import React, { Fragment, useState } from "react";
import Layout from "../component/common/Layout/Layout";
import Header from "../component/common/Header/Header";
import { Menu, Transition } from "@headlessui/react";
import { RiArrowDropDownLine } from "react-icons/ri";
import BarChart from "../component/common/Chart/BarChart";
import SingleAreaChart from "../component/common/Chart/SingleAreaChart";
import AreaChart from "../component/common/Chart/AreaChart";
import { Link } from "react-router-dom";
import { UseContextData } from "../context/dateContext";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Canvas = () => {
  // const [selectedButton, setSelectedButton] = useState(["harsh_accelearation"]);
  // const [selectedTimeStamp, setselectedTimeStamp] = useState("daily")
  // const [forecastedButton, setForecastedButton] = useState(["harsh_accelearation"])
  // const [forecastedTimeStamp, setForecastedTimeStamp] = useState("daily")
  const [maltivariateButton, setMaltivariateButton] = useState(
    "harsh_accelearation"
  );
  // const [maltivariateTimeStamp, setMaltivariateTimeStamp] = useState("daily")
  const {
    isDarkTheme,
    setIsDarkTheme,
    selectedTimeStamp,
    setselectedTimeStamp,
    selectedButton,
    setSelectedButton,
  } = UseContextData();
  const { t } = useTranslation();

  const handleCheckboxChange = (checkboxName) => {
    setSelectedButton((prevState) => {
      if (prevState.includes(checkboxName)) {
        return prevState.filter((item) => item !== checkboxName);
      } else {
        return [...prevState, checkboxName];
      }
    });
  };

  // const handleCheckbox = (checkboxName) => {
  //   setForecastedButton(prevState => {
  //     if (prevState.includes(checkboxName)) {
  //       return prevState.filter(item => item !== checkboxName);
  //     } else {
  //       return [...prevState, checkboxName];
  //     }
  //   });
  // };

  const translatedTimeStamp =
    selectedTimeStamp === "daily"
      ? t("timeStamps.daily")
      : selectedTimeStamp === "weekly"
      ? t("timeStamps.weekly")
      : selectedTimeStamp === "monthly"
      ? t("timeStamps.monthly")
      : t("timeStamps.default");

  // const translatedForcastTimeStamp =
  //   forecastedTimeStamp === "daily"
  //     ? t("timeStamps.daily")
  //     : forecastedTimeStamp === "weekly"
  //     ? t("timeStamps.weekly")
  //     : forecastedTimeStamp === "monthly"
  //     ? t("timeStamps.monthly")
  //     : t("timeStamps.default");

  // const translatedMaltivariateTimeStamp =
  //   maltivariateTimeStamp === "daily"
  //     ? t("timeStamps.daily")
  //     : maltivariateTimeStamp === "weekly"
  //     ? t("timeStamps.weekly")
  //     : maltivariateTimeStamp === "monthly"
  //     ? t("timeStamps.monthly")
  //     : t("timeStamps.default");

  const translatedButton =
    maltivariateButton === "harsh_accelearation"
      ? t("multivariateButtons.harsh_accelearation")
      : maltivariateButton === "Low_tire_pressure"
      ? t("multivariateButtons.Low_tire_pressure")
      : maltivariateButton === "sudden_braking"
      ? t("multivariateButtons.sudden_braking")
      : maltivariateButton === "Idle_time"
      ? t("multivariateButtons.Idle_time")
      : t("multivariateButtons.default");

  return (
    <Layout>
      <div className="h-full">
        <Header />

        <div className="">
          <div className="flex justify-between items-center gap-[30px] my-[24px]">
            <div className="flex items-center gap-[15px]">
              <h3 className="text-[24px] font-normal leading-[16px] tracking-[-0.48px] text-[#27403F] dark:text-white">
                {t("Canvas Page")}
              </h3>
              <div>
                <Menu
                  as="div"
                  className="relative inline-block text-left"
                >
                  <div>
                    <Menu.Button className="flex justify-start bg-white py-4 px-2 h-10 border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer text-[#01221A] text-[14px] font-bold leading-[14px]">
                      {t("Select")}
                      <button className="flex top-[15%] right-[3%] text-[30px]">
                        <RiArrowDropDownLine />
                      </button>
                      {/* <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right shadow-lg bg-white py-4 px-2 dark:bg-[#444444] dark:text-white dark:border-primary border border-primary rounded-lg items-center cursor-pointer">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] dark:bg-[#444444] dark:text-white"
                              )}
                            >
                              <input
                                type="checkbox"
                                name="harsh_accelearation"
                                checked={selectedButton.includes(
                                  "harsh_accelearation"
                                )}
                                onChange={() =>
                                  handleCheckboxChange("harsh_accelearation")
                                }
                              />
                              {t("Harsh Acceleration")}
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white"
                              )}
                            >
                              <input
                                type="checkbox"
                                name="Low_Tire_pressure_Count"
                                checked={selectedButton.includes(
                                  "Low_tire_pressure"
                                )}
                                onChange={() =>
                                  handleCheckboxChange("Low_tire_pressure")
                                }
                              />
                              {t("Low Tire pressure Count")}
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white"
                              )}
                            >
                              <input
                                type="checkbox"
                                name="Sudden_Braking"
                                checked={selectedButton.includes(
                                  "sudden_braking"
                                )}
                                onChange={() =>
                                  handleCheckboxChange("sudden_braking")
                                }
                              />
                              {t("Sudden Braking")}
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white"
                              )}
                            >
                              <input
                                type="checkbox"
                                name="Idle_Time"
                                checked={selectedButton.includes("Idle_time")}
                                onChange={() =>
                                  handleCheckboxChange("Idle_time")
                                }
                              />
                              {t("Idle Time")}
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex justify-between bg-white py-3 px-2 h-10 border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer text-[14px] font-bold leading-[14px] text-[#01221A]">
                      {translatedButton}
                      <button className="justify-between top-[15%] right-[3%] text-[30px] text-[#01221A]">
                        <RiArrowDropDownLine className="dark:text-white" />
                      </button>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-[237px] origin-top-right shadow-lg bg-white p-[16px_14px_16px_16px] border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] dark:bg-[#444444] dark:text-white"
                              )}
                              onClick={() =>
                                setMaltivariateButton("harsh_accelearation")
                              }
                            >
                              {t("Harsh Acceleration")}
                              {/* <button className='px-[10px] py-[5px] bg-[#5DA3E9] text-white text-[16px] ml-16 font-bold rounded-md flex items-center justify-center relative gap-[20px]' >Choose<img src="/assets/icons/vector.svg" alt="vector" className='mix-blend-screen absolute top-[-2px] left-[-5px]' /></button> */}
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white"
                              )}
                              onClick={() =>
                                setMaltivariateButton("Low_tire_pressure")
                              }
                            >
                              {t("Low Tire pressure Count")}
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white"
                              )}
                              onClick={() =>
                                setMaltivariateButton("sudden_braking")
                              }
                            >
                              {t("Sudden Braking")}
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white"
                              )}
                              onClick={() => setMaltivariateButton("Idle_time")}
                            >
                              {t("Idle Time")}
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <div className="flex gap-3">
              <div className="flex items-center gap-2 capitalize text-gray-600">
                <div className="w-3 h-3 rounded-full bg-[blue] "></div>
                <h3 className="dark:text-white">{t("technician 1")}</h3>
              </div>

              <div className="flex items-center gap-2 capitalize text-gray-600">
                <div className="w-3 h-3 rounded-full bg-[#00ff2a]"></div>
                <h3 className="dark:text-white">{t("technician 2")}</h3>
              </div>

              <div className="flex items-center gap-2 capitalize text-gray-600">
                <div className="w-3 h-3 rounded-full bg-[#c7c7c9]"></div>
                <h3 className="dark:text-white">{t("technician 3")}</h3>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-[14px] pb-[20px]">
            <div className="grid grid-rows-2 gap-[14px]">
              <div className="bg-[#fff] rounded-md p-[20px_23px_0px_13px] dark:bg-[#222222]">
                <div className="">
                  <div className="flex items-center justify-between">
                    <h3 className="text-xl font-normal text-[#27403F] mb-[8px] tracking-[-0.48px] pl-[25px] dark:text-white">
                      {t("See Trend")}
                    </h3>
                    <div className="flex items-center gap-[10px]">
                      {/* <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex justify-between bg-white py-3 px-2 h-10 border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer text-[14px] font-bold leading-[14px] text-[#01221A]">
                            {t('Select')}
                            <button className='justify-between top-[15%] right-[3%] text-[30px] text-[#01221A]'><RiArrowDropDownLine className='dark:text-white' /></button>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-[237px] origin-top-right shadow-lg bg-white p-[16px_14px_16px_16px] border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] dark:bg-[#444444] dark:text-white'
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      name="harsh_accelearation"
                                      checked={selectedButton.includes("harsh_accelearation")}
                                      onChange={() => handleCheckboxChange("harsh_accelearation")}
                                    />
                                    {t('Harsh Acceleration')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      name="Low_Tire_pressure_Count"
                                      checked={selectedButton.includes("Low_tire_pressure")}
                                      onChange={() => handleCheckboxChange("Low_tire_pressure")}
                                    />
                                    {t('Low Tire pressure Count')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      name="Sudden_Braking"
                                      checked={selectedButton.includes("sudden_braking")}
                                      onChange={() => handleCheckboxChange("sudden_braking")}
                                    />
                                    {t('Sudden Braking')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      name="Idle_Time"
                                      checked={selectedButton.includes("Idle_time")}
                                      onChange={() => handleCheckboxChange("Idle_time")}
                                    />
                                    {t('Idle Time')}
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu> */}

                      {/* <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex justify-start w-[116px] bg-white p-[13px_11px] h-10 border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer text-[14px] font-bold leading-[14px] text-[#01221A]">
                          {translatedTimeStamp}
                            <button className='absolute top-[15%] right-[3%] text-[30px]'><RiArrowDropDownLine /></button>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-[237px] origin-top-right shadow-lg bg-white p-[16px_14px_16px_16px] border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer">
                            <div className="py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] dark:bg-[#444444] dark:text-white'
                                    )}
                                    onClick={() => setselectedTimeStamp("daily")}
                                  >
                                    {t('Daily')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                    onClick={() => setselectedTimeStamp("weekly")}
                                  >
                                    {t('Weekly')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                    onClick={() => setselectedTimeStamp("monthly")}
                                  >
                                    {t('Monthly')}
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu> */}
                    </div>
                  </div>
                </div>
                <BarChart
                  selectedButton={selectedButton}
                  selectedTimeStamp={selectedTimeStamp}
                  isDarkTheme={isDarkTheme}
                />
              </div>

              <div className="bg-[#fff] rounded-md p-[20px_23px_0px_13px] dark:bg-[#222222]">
                <div className="">
                  <div className="flex items-center justify-between mt-[5px]">
                    <h3 className="text-xl font-normal text-[#27403F] mb-[20px] tracking-[-0.48px] pl-[25px] dark:text-white">
                      {t("Forecasting")}
                    </h3>
                    <div className="flex items-center gap-[10px]">
                      {/* <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex justify-between bg-white py-3 px-2 h-10 border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer text-[14px] font-bold leading-[14px] text-[#01221A]">
                            {t('Select')}
                            <button className='justify-between top-[15%] right-[3%] text-[30px] text-[#01221A]'><RiArrowDropDownLine className='dark:text-white' /></button>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-[237px] origin-top-right shadow-lg bg-white p-[16px_14px_16px_16px] border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer">
                            <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] dark:bg-[#444444] dark:text-white'
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      name="harsh_accelearation"
                                      checked={forecastedButton.includes("harsh_accelearation")}
                                      onChange={() => handleCheckbox("harsh_accelearation")}
                                    />
                                    {t('Harsh Acceleration')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      name="Low_Tire_pressure_Count"
                                      checked={forecastedButton.includes("Low_tire_pressure")}
                                      onChange={() => handleCheckbox("Low_tire_pressure")}
                                    />
                                    {t('Low Tire pressure Count')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      name="Sudden_Braking"
                                      checked={forecastedButton.includes("sudden_braking")}
                                      onChange={() => handleCheckbox("sudden_braking")}
                                    />
                                    {t('Sudden Braking')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center gap-[5px] text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                  >
                                    <input
                                      type="checkbox"
                                      name="Idle_Time"
                                      checked={forecastedButton.includes("Idle_time")}
                                      onChange={() => handleCheckbox("Idle_time")}
                                    />
                                    {t('Idle Time')}
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu> */}

                      {/* <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex justify-start w-[116px] bg-white p-[13px_11px] h-10 border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer text-[14px] font-bold leading-[14px] text-[#01221A]">
                            {translatedTimeStamp}
                            <button className='absolute top-[15%] right-[3%] text-[30px]'><RiArrowDropDownLine /></button>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-[237px] origin-top-right shadow-lg bg-white p-[16px_14px_16px_16px] border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] dark:bg-[#444444] dark:text-white'
                                    )}
                                    onClick={() => setForecastedTimeStamp("daily")}
                                  >
                                    {t('Daily')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                    onClick={() => setForecastedTimeStamp("weekly")}
                                  >
                                    {t('Weekly')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                    onClick={() => setForecastedTimeStamp("monthly")}
                                  >
                                    {t('Monthly')}
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu> */}
                    </div>
                  </div>
                </div>
                <SingleAreaChart
                  forecastedButton={selectedButton}
                  forecastedTimeStamp={selectedTimeStamp}
                  isDarkTheme={isDarkTheme}
                />
              </div>
            </div>

            <div className="bg-[#fff] rounded-md  p-[25px_0px_0px_21px] dark:bg-[#222222]">
              <div className="">
                <div className="flex flex-col justify-start gap-[22px]">
                  <h3 className="text-xl font-normal text-[#27403F] leading-[16px] tracking-[-0.48px] dark:text-white">
                    {t("Multivariate Analysis")}
                  </h3>
                  <div className="flex items-center gap-[10px]">
                    {/* <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="flex justify-between bg-white py-3 px-2 h-10 border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer text-[14px] font-bold leading-[14px] text-[#01221A]">
                          {translatedButton}
                          <button className="justify-between top-[15%] right-[3%] text-[30px] text-[#01221A]">
                            <RiArrowDropDownLine className="dark:text-white" />
                          </button>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-[237px] origin-top-right shadow-lg bg-white p-[16px_14px_16px_16px] border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] dark:bg-[#444444] dark:text-white"
                                  )}
                                  onClick={() =>
                                    setMaltivariateButton("harsh_accelearation")
                                  }
                                >
                                  {t("Harsh Acceleration")}
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white"
                                  )}
                                  onClick={() =>
                                    setMaltivariateButton("Low_tire_pressure")
                                  }
                                >
                                  {t("Low Tire pressure Count")}
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white"
                                  )}
                                  onClick={() =>
                                    setMaltivariateButton("sudden_braking")
                                  }
                                >
                                  {t("Sudden Braking")}
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white"
                                  )}
                                  onClick={() =>
                                    setMaltivariateButton("Idle_time")
                                  }
                                >
                                  {t("Idle Time")}
                                </Link>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu> */}

                    {/* <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="flex justify-start w-[116px] bg-white p-[13px_11px] h-10 border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer text-[14px] font-bold leading-[14px] text-[#01221A]">
                          {selectedTimeStamp}
                          <button className='absolute top-[15%] right-[3%] text-[30px] text-[#01221A]'><RiArrowDropDownLine className='dark:text-white' /></button>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-[237px] origin-top-right shadow-lg bg-white p-[16px_14px_16px_16px] border dark:bg-[#444444] dark:text-white dark:border-primary border-primary rounded-lg items-center cursor-pointer">
                          <div className="py-1">
                          <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] dark:bg-[#444444] dark:text-white'
                                    )}
                                    onClick={() => setMaltivariateTimeStamp("daily")}
                                  >
                                    {t('Daily')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                    onClick={() => setMaltivariateTimeStamp("weekly")}
                                  >
                                    {t('Weekly')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'flex items-center justify-between text-[#27403F] text-[10px] font-bold leading-[10px] mt-[16px] dark:bg-[#444444] dark:text-white'
                                    )}
                                    onClick={() => setMaltivariateTimeStamp("monthly")}
                                  >
                                    {t('Monthly')}
                                  </Link>
                                )}
                              </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu> */}
                  </div>
                </div>
              </div>
              <AreaChart
                maltivariateButton={maltivariateButton}
                maltivariateTimeStamp={selectedTimeStamp}
                isDarkTheme={isDarkTheme}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Canvas;
