import React, { useState, useEffect } from "react";
import Odometer from "react-odometerjs";

const OdometerBox = ({ currencyType, amount }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => setValue(amount), 500);
    // const timeoutId = setTimeout(() => setValue(4321.25), 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, amount]);
  const digitsArray = Array.from(String(value), Number);
  return (
    <>
      <div className="text-center flex  gap-1 items-center ">
        <div className={`${digitsArray.length > 10 ? "odometer-digit-mein" : "assas"}   `}>
          <Odometer value={value} format="(ddd).dd" />
        </div>
        <div className="flex justify-center ">
          {currencyType.split("").map((alpha, index) => {
            return (
              <div
                className="text-[#787F89] text-[12px]  font-normal"
                key={index}
              >
                {alpha}
              </div>
              //   <div className="mt-3">
              //   {
              //     currencyType.split('').map((alpha,index) => {
              //       return <div className="odometer-formatting-mark !items-center" key={index}>{alpha}</div>
              //     })
              //   }
              // </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OdometerBox;
