import React from 'react'
import { Radar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const SpiderChart = ({ stateData, isDarkTheme }) => {

  const backgroundColor = isDarkTheme ? "#000" : "gray";
  const textColor = isDarkTheme ? "#ffffff" : "gray";
  const spiderColor = isDarkTheme ? "rgba(243, 126, 68)" : "rgba(255, 99, 132, 1)";
  const { t } = useTranslation();

  const labels = ['Speed', 'Tire Pressure', 'Idle Time', 'Speed', 'Tire Pressure', 'Idle Time'];
  const translatedLabels = labels?.map(label => t(label));


  const data = {
    labels: translatedLabels,
    datasets: [
      {
        // label: ['Speed-issue', 'Speed-future', 'Idle Time-issue', 'Tire Pressure-issue', 'Tire Pressure-future', 'Idle Time-future'],
        backgroundColor: isDarkTheme ? 'rgba(243, 126, 68, 0.2)' : 'rgba(255, 99, 132, 0.2)',
        borderColor: spiderColor,
        pointBackgroundColor: spiderColor,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: spiderColor,
        data: [stateData?.speed_issue, stateData?.LowTirePressure_issue, stateData?.Idling_issue, stateData?.Future_speed_issue, stateData?.Future_LowTirePressure_issue, stateData?.Future_Idling_issue,],
      },
      {
        // label: ['Speed-issue', 'Speed-future', 'Idle Time-issue', 'Tire Pressure-issue', 'Tire Pressure-future', 'Idle Time-future'],
        backgroundColor: 'rgba(169, 169, 169, 0.2)',
        borderColor: 'rgba(169, 169, 169, 1)',
        pointBackgroundColor: 'rgba(169, 169, 169, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(169, 169, 169, 1)',
        // data: [50, 35, 15, 50, 35, 15,],
        // data: [1500, 1500, 1500, 1500, 1500, 1500 ]
      },
    ],
  };

  const options = {
    scale: {
      ticks: { beginAtZero: true },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          display: false,
        }
      },
    },
    scales: {
      r: {
        ticks: {
          display: false
        },
        grid: {
          color: backgroundColor,
        },
        angleLines: {
          color: backgroundColor,
        },
        pointLabels: {
          color: textColor
        }
      }
    }
  };
  return (
    <div>
      <Radar data={data} options={options} />
    </div>
  )
}

export default SpiderChart