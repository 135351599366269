import React, { useEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import { useDispatch } from 'react-redux';
import { getBatteryTempratureData } from '../../redux/action/action';
import { format, addMinutes } from 'date-fns';
import { UseContextData } from '../../context/dateContext';
import { useTranslation } from 'react-i18next';

highcharts3d(Highcharts);

const BatterynetTempratureChart = ({ toggleTempPopup, isDarkTheme }) => {
  const [chartData, setChartData] = useState();
  const dispatch = useDispatch();
  const [dynamicLabels, setDynamicLabels] = useState([]);
  const { formatDate } = UseContextData()
  const IndexValue = useRef(0);
  const TextColor = isDarkTheme ? "#ffffff" : "#000000";
  const backgroundColor = isDarkTheme ? "#222222" : "#ffffff";
  const { t } = useTranslation()

  useEffect(() => {
    const loadData = async () => {
      const requestParams = { index: IndexValue.current };
      try {
        const response = await dispatch(getBatteryTempratureData(requestParams));
        const responseData = response?.payload?.data;
        if (responseData) {
          setChartData(responseData);
          setDynamicLabels(generateTimeLabels(7));
          IndexValue.current = response?.payload?.last_index || 0;
          if (responseData?.anomaly?.some((value) => value === 1)) {
            toggleTempPopup(true);
          } else {
            toggleTempPopup(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    loadData();
    const intervalId = setInterval(() => {
      loadData();
    }, 30000);
    return () => clearInterval(intervalId);
  }, [dispatch, formatDate]);

  const generateTimeLabels = (length) => {
    const previousTimes = Array.from({ length }, (_, index) => format(addMinutes(formatDate, -index), 'hh:mm'));
    return previousTimes.reverse();
  };

  const markerPositions = chartData?.anomaly?.map((value, index) => value === 1 ? index : null)?.filter((value) => value !== null) || [];
  const options = {
    chart: {
      type: 'area',
      height: '310px',
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 332,
        depth: 100,
      },
      backgroundColor: backgroundColor,
    },
    title: {
      style: {
        display: 'none'
      }
    },

    xAxis: {
      categories: dynamicLabels || [],
      labels: {
        rotation: -45,
        step: 1,
        style: {
          color: TextColor,
        },
      },
    },
    yAxis: {
      title: {
        text: t('Temperature(degree/celsius)'),
        offset: 50,
        style: {
          color: TextColor,
        },
      },
      labels: {
        style: {
          color: TextColor,
        },
      },
    },
    zAxis: {
      title: {
        text: '',
      },
    },
    accessibility: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: TextColor,
      },
    },
    plotOptions: {
      area: {
        fillOpacity: 0.8,
        depth: 70,
        states: {
          inactive: {
            enabled: false
          }
        }
      },
      series: {
        depth: 45,
        animation: true,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: t('Battery Temperature'),
        color: 'rgba(0, 175, 249, 0.5)',
        data: (chartData?.battery_temp || [])?.map((value, index) => ({
          x: index,
          y: value,
          marker: {
            enabled: markerPositions.includes(index),
            symbol: 'circle',
            radius: 5,
            fillColor: '#ff0000',
            lineWidth: 2,
            lineColor: '#fff',
          },
        })),
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default BatterynetTempratureChart