import { Popover, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6'

const ChooseTime = () => {
  const { t } = useTranslation()
  return (
    <>
      <React.Fragment>
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={`cursor-pointer flex items-center justify-center text-sm mx-auto outline-none`}

              >
                {t('Today')} {open ? <FaChevronUp className='inline text-sm ml-1' />:  <FaChevronDown className='inline text-sm ml-1' /> }
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -right-2 sm:right-auto sm:left-0 z-10 mt-2 w-[90vw] sm:w-screen max-w-sm transform lg:max-w-[380px]">
                  <div className="overflow-hidden border border-primary p-3 rounded-lg shadow-menu ring-1 ring-black/5 bg-white dark:bg-[#444444] dark:shadow-[0px_0px_2px_white]">
                    <div className='flex justify-between items-center mb-3'>
                      <div className='font-bold'>{t('Last Week')}</div>
                      <button className='bg-[#5DA3E9] font-bold text-white p-2 rounded-lg'>{t('Choose')}</button>
                    </div>
                    <div className='flex justify-between items-center'>
                      <div className='font-bold'>{t('Last Month')}</div>
                      <button className='bg-[#5DA3E9] font-bold text-white p-2 rounded-lg'>{t('Choose')}</button>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </React.Fragment>
    </>
  )
}

export default ChooseTime
