import React from 'react'
import Sidebar from '../Sidebar';


const Layout = ({ children }) => {

  return (
    <>
      <div className='bg-white bg-opacity-80 min-h-screen'>
        <Sidebar />
        <div className='ml-[300px] p-8 pb-0 relative z-20 bg-[#c8d9d663] h-full  min-h-screen dark:bg-[#1d1d1d]'>
          {children}
        </div>

        {/* center left  */}
        <div className='absolute w-[1324px] top-[0] left-[-50%] min-h-full bg-[#E0E5F2] blur-[310px] z-10'></div>

        {/* top right */}
        <div className='absolute w-[1324px] top-0 -translate-y-1/2 right-0 min-h-full bg-[#EFEFEF] blur-[310px] z-10'></div>

        {/* bottom left */}
        {/* <div className='absolute w-[820px] bottom-0 left-[10%] translate-y-1/2 right-0 h-[820px] bg-[#C8D9D6] rounded-full blur-[310px] z-10'></div> */}

        {/* center  */}
        {/* <div className='absolute w-[820px] top-1/2 right-0 -translate-y-1/2  h-[820px] bg-[#C8D9D6] rounded-full blur-[310px] z-10'></div> */}

      </div>
    </>
  )
}

export default Layout