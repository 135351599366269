import React, { useState } from "react";
import { forgetPassword } from "../redux/action/action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Email is required");
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }
    const requestParams = { email: email };
    await dispatch(forgetPassword(requestParams)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.data?.message);
      } else {
        setErrorMessage(res?.payload?.data?.message || "An error occurred");
      }
      setEmail("");
    });
  };

  return (
    <div>
      <div className="h-screen  from-bg  grid place-content-center">
        <div className="w-[800px] h-[800px] rounded-full bg-[#F7F9F9] flex items-center  flex-col p-5 text-center">
          <div className="mx-auto mt-[150px]">
            <img alt="" src="assets/icons/logo.svg" />
          </div>
          <h2 className="text-[#00AEB6] text-[36px] font-bold mt-[31px]">
            Reset Password
          </h2>
          <h3 className="text-[#93B9C4] text-[16px]">create a new password</h3>
          {errorMessage && (
            <p className="text-[red] text-lg mt-[10px]">{errorMessage}</p>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mx-auto mt-[30px]">
              <input
                type="email"
                className="w-[465px] h-[60px] rounded-md pl-[27px]  focus:outline-none hover:outline-none font-[600] placeholder:text-[#6FA2B0] text-[#6FA2B0]"
                placeholder="Email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                // required
              />
              <br />
            </div>

            <button
              className="mt-[43px] bg-[#00AEB6] rounded-lg mx-auto w-[187px] h-[50px] text-white text-lg font-bold"
              type="submit"
            >
              send
            </button>
          </form>

          <h2 className="mt-[23px] text-[16px] text-[#00AEB6] font-[500]">
            <span className="font-bold ml-2 cursor-pointer">
              please,check your mail box
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
