import React, { useEffect, useRef } from 'react'

const VehiclesTwinGasStaion = ({ vinLocation, onDistanceChange }) => {
  const ref = useRef(null);
  let map;
  let directionsService;
  let directionsRenderer;

  useEffect(() => {
    const initializeMap = () => {
      if (!map) {
        const LatLng = new window.google.maps.LatLng(vinLocation?.startLatLng?.lat, vinLocation?.startLatLng?.lng);
        map = new window.google.maps.Map(ref.current, {
          center: LatLng,
          zoom: 12,
          // mapTypeId: window.google.maps.MapTypeId.HYBRID,
        });
        directionsService = new window.google.maps.DirectionsService();
        directionsRenderer = new window.google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
      }

      if (vinLocation && directionsService && directionsRenderer) {
        calculateAndDisplayRouteForRealTime();
      } else {
        directionsRenderer.setMap(null);
      }
    };
    initializeMap();
  }, [vinLocation]);

  const calculateAndDisplayRouteForRealTime = () => {
    if (
      vinLocation?.startLatLng?.lat &&
      vinLocation?.startLatLng?.lng
    ) {
      const startLatLng = new window.google.maps.LatLng(
        vinLocation?.startLatLng?.lat,
        vinLocation?.startLatLng?.lng
      );

      const markerIcon = "/assets/icons/headercarlogoGray.svg"
      addMarker(startLatLng, 'You', markerIcon);
      searchNearbyGasStations(startLatLng);
    } else {
      directionsRenderer.setMap(null);
      if (map) {
        map.setCenter(new window.google.maps.LatLng(40.273502, -86.126976));
      }
    }
  };

  const searchNearbyGasStations = (startLatLng) => {
    const request = {
      location: startLatLng,
      radius: 1000000,
      types: ['gas_station'], // 'petrol_station', 'diesel_station'
    };

    const placesService = new window.google.maps.places.PlacesService(map);
    placesService.nearbySearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        handleNearbyGasStations(results, startLatLng);
      } else {
        console.error('Error fetching nearby gas stations:', status);
      }
    });
  };

  const handleNearbyGasStations = (gasStations, userLocation) => {
    let nearestGasStation = null;
    let shortestDistance = Infinity;

    gasStations.forEach((station) => {
      const stationLocation = new window.google.maps.LatLng(
        station.geometry.location.lat(),
        station.geometry.location.lng()
      );

      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        userLocation,
        stationLocation
      );

      if (distance < shortestDistance) {
        nearestGasStation = station;
        shortestDistance = distance;
      }
    });

    if (nearestGasStation) {
      const markerIcon = "/assets/icons/gas.png"
      addMarker(nearestGasStation.geometry.location, nearestGasStation.name, markerIcon);
      addDirections(userLocation, nearestGasStation.geometry.location);
      const distanceInKm = shortestDistance / 1609.344;
      onDistanceChange(distanceInKm);
    }
  };

  const addDirections = (origin, destination) => {
    directionsService.route(
      {
        origin,
        destination,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
          directionsRenderer.setOptions({
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: "#2A2E35",
              strokeWeight: 5,
            },
          });
        } else {
          console.error('Directions request failed due to ', status);
          directionsRenderer.setMap(null);
        }
      }
    );
  };

  const getMarkerIcon = (category) => {
    if (category === 'High') {
      return '/assets/icons/headercarlogoGreen.svg';
    } else if (category === 'Medium') {
      return '/assets/icons/headercarlogoYellow.svg';
    } else if (category === 'Low') {
      return '/assets/icons/headercarlogoRed.svg';
    }
  };

  const addMarker = (position, title, icon) => {
    new window.google.maps.Marker({
      map,
      position,
      title,
      icon: {
        url: icon,
        scaledSize: new window.google.maps.Size(30, 30),
      },
    });
  };

  return (
    <div
      ref={ref}
      id="map"
      style={{ borderRadius: "8px", width: "100%", height: "100%" }}
    />
  )
}

export default VehiclesTwinGasStaion