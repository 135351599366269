import React, { useEffect, useState } from "react";
import Layout from "../component/common/Layout/Layout";
import Header from "../component/common/Header/Header";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import Icon from "@mdi/react";
import { mdiCheckboxMarkedCircle, mdiChevronDown } from "@mdi/js";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { getMapData, getVehicleInfo } from "../redux/action/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UseContextData } from "../context/dateContext";

const FleetViewMap = ({ modal }) => {
  const [vehiclesLatLng, setVehiclesLatLng] = useState([]);
  const { date } = UseContextData();
  const [selectedVinId, setSelectedVinId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mapKey = process.env.REACT_APP_VITE_MAP_KEY;
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [clickedVehicle, setClickedVehicle] = useState(null);
  const [clickTimeout, setClickTimeout] = useState(null);

  const [center, setCenter] = useState({
    lat: 40.4613484576501,
    lng: -86.3732816459897,
  });

  const mapOptions = {
    center: center,
    zoom: modal === undefined && true ? 9 : 6,
    // mapTypeId: window.google && window.google.maps ? window.google.maps.MapTypeId.HYBRID : null,
    // streetViewControl: false,
    // disableDefaultUI: true,
    // zoomControl: false,
    streetViewControl: false,
    zoomControl: true,
    mapTypeControl: true, // Disable default map type control
    mapTypeControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      // mapTypeIds: ['roadmap', 'satellite']
    },
    fullscreenControl: true, // Disable default fullscreen control,
    fullscreenControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM, // Set the position of the fullscreen control
    },
    scaleControl: false,
    rotateControl: false,
    panControl: false,
    gestureHandling: "greedy",
  };

  // useEffect(() => {
  //   if (date !== 'Invalid Date') {
  //     getFleetVehicleLatLngService();
  //   }
  // }, [date, modal]);

  // const getFleetVehicleLatLngService = async () => {
  //   const requestParams = { date: date };
  //   try {
  //     const response = await dispatch(getMapData(requestParams))
  //     const responseData = response?.payload?.data
  //     if (responseData) {
  //       if (JSON.stringify(responseData) !== JSON.stringify(vehiclesLatLng)) {
  //         setVehiclesLatLng(responseData);
  //       }
  //     } else {
  //       setVehiclesLatLng([])
  //     }
  //   } catch (error) {
  //     console.log("🚀 ~ error:", error);
  //   }
  // }

  const [vinRealTimeData, setVinRealTimeData] = useState({
    idleTime: 0,
    fuelEfficiency: 0,
    speed: 0,
    fl: 0,
    fr: 0,
    rr: 0,
    rl: 0,
    fleet_Speeding_saving_data: 0,
    fleet_Idling_Saving_data: 0,
    fleet_Tire_Pressure_Saving_data: 0,
  });

  const vinRealTimeDataService = async () => {
    if (!selectedVinId) return;
    const requestParams = { vin: selectedVinId };

    try {
      const res = await dispatch(getVehicleInfo(requestParams));
      if (res && res?.payload) {
        const responseData = res?.payload?.data;
        setVinRealTimeData({
          ...vinRealTimeData,
          idleTime: +responseData?.idletime || 0,
          fuelEfficiency: +responseData?.fuel_efficiency?.toFixed(2) || 0,
          speed: +responseData?.speed || 0,
          fl: +responseData?.FL.toFixed(2) || 0,
          fr: +responseData?.FR.toFixed(2) || 0,
          rr: +responseData?.RR.toFixed(2) || 0,
          rl: +responseData?.RL.toFixed(2) || 0,
          fleet_Speeding_saving_data:
            +responseData?.fleet_Speeding_saving_data.toFixed(2) || 0,
          fleet_Idling_Saving_data:
            +responseData?.fleet_Idling_Saving_data.toFixed(2) || 0,
          fleet_Tire_Pressure_Saving_data:
            +responseData?.fleet_Tire_Pressure_Saving_data.toFixed(2) || 0,
        });
      } else {
        setVinRealTimeData({
          idleTime: 0,
          fuelEfficiency: 0,
          speed: 0,
          fl: 0,
          fr: 0,
          rr: 0,
          rl: 0,
          fleet_Speeding_saving_data: 0,
          fleet_Idling_Saving_data: 0,
          fleet_Tire_Pressure_Saving_data: 0,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMouseOver = (vin) => {
    setSelectedVinId(vin);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (date && date !== "Invalid Date") {
        try {
          const response = await dispatch(getMapData({ date }));
          const responseData = response?.payload?.data;
          if (responseData) {
            setVehiclesLatLng(responseData);
          } else {
            setVehiclesLatLng([]);
          }
        } catch (error) {
          console.log("🚀 ~ error:", error);
        }
      }
    };

    fetchData();
    vinRealTimeDataService();
  }, [date, dispatch, selectedVinId]);

  // const onMarkerClick = (vin) => {
  //   setSelectedVinId(vin);
  //   navigate({
  //     pathname: "/digital_twins",
  //     search: `?selectedVin=${vin}`,
  //   });
  // };

  const handleMarkerClick = (vehicle) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    } else {
      setTimeout(() => {
        setCenter({ lat: vehicle?.latitude, lng: vehicle?.longitude });
        setSelectedVinId(vehicle.vin);
        setClickedVehicle(vehicle);
        setInfoWindowOpen(true);
      }, 200);
      setClickTimeout(null);
    }
  };

  const handleMarkerDoubleClick = (vin) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }
    setSelectedVinId(vin);
    navigate({
      pathname: "/digital_twins",
      search: `?selectedVin=${vin}`,
    });
  };
  return (
    <>
      {/*     
    // <Layout>
    //   <div className='h-full'>
    //     <Header selectedMenu={(selectedOption) => selectedOption}>
    //       <div className='flex items-center gap-[14px] min-w-[282px]'>
    //         <div className='flex items-center'>
    //           <div className='text-dark1 font-medium text-xs mr-2'>View in map:</div>
    //           <div>
    //             <Menu placement='bottom-start'>
    //               <MenuHandler>
    //                 <div className='py-3 px-2 h-10 bg-white w-[112px] border border-primary rounded-lg flex items-center justify-between cursor-pointer'>
    //                   <div className='text-sm'>Midwest</div>
    //                   <Icon path={mdiChevronDown} size={1} className='ml-2' />
    //                 </div>
    //               </MenuHandler>
    //               <MenuList className='min-w-[200px]'>
    //                 <MenuItem>
    //                   <div className='items-center text-dark1 font-medium text-xs'>
    //                     <Icon path={mdiCheckboxMarkedCircle} size={"16px"} className='mr-2 inline text-purple' />
    //                     All
    //                   </div>
    //                 </MenuItem>
    //                 <MenuItem>
    //                   <div className='items-center text-dark1 font-medium text-xs'>
    //                     <Icon path={mdiCheckboxMarkedCircle} size={"16px"} className='mr-2 inline text-[#519C55]' />
    //                     Best performer
    //                   </div>
    //                 </MenuItem>
    //                 <MenuItem>
    //                   <div className='inline-flex items-center text-dark1 font-medium text-xs'>
    //                     <Icon path={mdiCheckboxMarkedCircle} size={"16px"} className='mr-2 inline text-[#F2C049]' />
    //                     Average performer
    //                   </div>
    //                 </MenuItem>
    //                 <MenuItem>
    //                   <div className='inline-flex items-center text-dark1 font-medium text-xs'>
    //                     <Icon path={mdiCheckboxMarkedCircle} size={"16px"} className='mr-2 inline text-[#E33233]' />
    //                     Baseline performer
    //                   </div>
    //                 </MenuItem>
    //               </MenuList>
    //             </Menu>
    //           </div>
    //         </div>
    //         <button onClick={() => { navigate('/fleet_view') }} className='max-w-[78px] px-4 py-2 bg-[#5DA3E9] text-white text-[16px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px]'>Close<img src="/assets/icons/vector.svg" alt="vector" className='mix-blend-screen absolute top-[-3px] left-[-6px]' /></button>
    //       </div>
    //     </Header>
    //     <div className='h-[calc(100%_-_40px)] pt-6'>
    //       <Wrapper apiKey={mapKey} libraries={["places"]}> */}
      <GoogleMap
        mapContainerStyle={{
          minHeight: modal === undefined && true ? "800px" : "350px",
          Width: modal === undefined && true ? "1000px" : "350px",
          marginBottom: "0",
          height: "100%",
          position: "relative",
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
        options={mapOptions}
        onLoad={() => console.log("Map loaded")}
      >
        {vehiclesLatLng?.map((vehicle, index) => (
          <Marker
            key={index}
            position={{ lat: vehicle.latitude, lng: vehicle.longitude }}
            icon={{
              url:
                vehicle?.fuel_efficiency_max > 60
                  ? "/assets/icons/markerGreen.png"
                  : vehicle?.fuel_efficiency_max > 30
                  ? "/assets/icons/markerYellow.png"
                  : "/assets/icons/markerRed.png",
              scaledSize: { width: 20, height: 20 },
            }}
            onClick={() => handleMarkerClick(vehicle)}
            onDblClick={() => handleMarkerDoubleClick(vehicle.vin)}
            onMouseOver={() => handleMouseOver(vehicle.vin)}
          />
        ))}
        {infoWindowOpen && clickedVehicle && (
          <>
            <InfoWindow
              position={{
                lat: clickedVehicle.latitude,
                lng: clickedVehicle.longitude,
              }}
              onCloseClick={() => {
                setInfoWindowOpen(false);
                setCenter({ lat: 40.4613484576501, lng: -86.3732816459897 });
              }}
            >
              <>
                <div>
                  <p>{`Efficiency: ${clickedVehicle?.fuel_efficiency_max?.toFixed(
                    2
                  )} %`}</p>
                  <p>{`Maintain the vehicle average speed of ${vinRealTimeData?.speed} mph, and save up to ${vinRealTimeData?.fleet_Speeding_saving_data} in next 1000 miles.`}</p>
                  <p>{`Don't leave the vehicle idle for more than ${vinRealTimeData?.idleTime.toFixed(
                    2
                  )} mins, it may cost ${
                    vinRealTimeData?.fleet_Idling_Saving_data
                  }`}</p>
                  <p>{`Increase the tire pressure by FL-${vinRealTimeData?.fl}, FR-${vinRealTimeData?.fr}, RR-${vinRealTimeData?.rr}, RL-${vinRealTimeData?.rl} you may save up to`}</p>
                </div>
              </>
            </InfoWindow>
          </>
        )}
      </GoogleMap>

      {/* //       </Wrapper> */}
      {/* //     </div> */}
      {/* //   </div> */}
      {/* // </Layout> */}
    </>
  );
};

export default FleetViewMap;
