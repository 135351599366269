import React, { useEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import { useDispatch } from 'react-redux';
import { getForecastingData } from '../../../redux/action/action';
import { useTranslation } from 'react-i18next';

highcharts3d(Highcharts);

const SingleAreaChart = ({ forecastedButton, forecastedTimeStamp, isDarkTheme }) => {

  const dispatch = useDispatch()

  const IndexValue = useRef(0);
  const [chartData, setChartData] = useState(null)
  const TextColor = isDarkTheme ? "#ffffff" : "#000000";
  const backgroundColor = isDarkTheme ? "#222222" : "#ffffff";
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      const requestParams = { index: IndexValue.current, field_name: forecastedButton, canvas: forecastedTimeStamp };
      try {
        const response = await dispatch(getForecastingData(requestParams));
        if (response && response?.payload) {
          const responseData = response?.payload;
          setChartData(responseData);
          IndexValue.current = responseData?.last_index || 0;
        }
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    };
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [dispatch, forecastedButton, forecastedTimeStamp]);

  if (!chartData) {
    return <div>Loading...</div>;
  }

  const calculateColorByIndex = (index, forecastedTimeStamp) => {
    let blueZonePoints, redZonePoints;
    if (forecastedTimeStamp === 'daily') {
      blueZonePoints = 10;
      redZonePoints = 4;
    } else if (forecastedTimeStamp === 'weekly') {
      blueZonePoints = 5;
      redZonePoints = 3;
    } else if (forecastedTimeStamp === 'monthly') {
      blueZonePoints = 3;
      redZonePoints = 2;
    }

    if (index < blueZonePoints) {
      return Highcharts.getOptions().colors[0];
    } else {
      return "#59698b";
    }
  };

  const getEnableDataLabelCondition = (index, forecastedTimeStamp) => {
    if (forecastedTimeStamp === 'daily' && index === 10) {
      return true;
    } else if (forecastedTimeStamp === 'weekly' && index === 5) {
      return true;
    } else if (forecastedTimeStamp === 'monthly' && index === 3) {
      return true;
    } else {
      return false;
    }
  };

  const calculatePlotLineValue = (forecastedTimeStamp, seriesData) => {
    if (forecastedTimeStamp === 'daily') {
      return Math.min(10, seriesData.length - 1);
    } else if (forecastedTimeStamp === 'weekly') {
      return Math.min(5, seriesData.length - 1);
    } else if (forecastedTimeStamp === 'monthly') {
      return Math.min(3, seriesData.length - 1);
    }
    return 0;
  };

  const hexToRgba = (hex, opacity) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r},${g},${b},${opacity})`;
  };

  const series = Object.keys(chartData).filter(key => key !== 'Date' && key !== 'last_index').map((key, index) => {
    const color = Highcharts.getOptions().colors[index % Highcharts.getOptions().colors.length];
    const zoneColor = hexToRgba(color, 0.6);
    return {
      name:
        key === "harsh_accelearation"
          ? t("multivariateButtons.harsh_accelearation")
          : key === "Low_tire_pressure"
          ? t("multivariateButtons.Low_tire_pressure")
          : key === "sudden_braking"
          ? t("multivariateButtons.sudden_braking")
          : key === "Idle_time"
          ? t("multivariateButtons.Idle_time")
          : key,
      data: chartData[key].map((point, idx) => ({
        y: Number(point.toFixed(2)),
        color: calculateColorByIndex(idx, forecastedTimeStamp),
        dataLabels: {
          enabled: getEnableDataLabelCondition(idx, forecastedTimeStamp),
          align: "left",
          x: 4,
          y: -70,
          format: t("forecast"),
          style: {
            color: TextColor,
            fontSize: "12px",
          },
        },
      })),
      zoneAxis: "x",
      zones: [
        {
          value: calculatePlotLineValue(forecastedTimeStamp, chartData[key]),
          color: zoneColor,
          marker: {
            fillColor: zoneColor,
          },
        },
        {
          value: chartData[key].length - 1,
          dashStyle: "dash",
          color: "rgba(89, 105, 139, 0.6)",
          marker: {
            fillColor: "rgba(89, 105, 139, 0.6)",
          },
        },
      ],
    };
  });

  const options = {
    chart: {
      type: 'areaspline',
      height: '300px',
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 0,
        depth: 0,
      },
      backgroundColor: backgroundColor,
    },
    title: {
      style: {
        display: 'none'
      }
    },
    xAxis: {
      categories: chartData?.Date || [],
      labels: {
        rotation: -25,
        step: 1,
        style: {
          fontSize: 10,
          color: TextColor,
        },
      },
      plotLines: [
        {
          color: TextColor,
          dashStyle: 'dash',
          value: calculatePlotLineValue(forecastedTimeStamp, chartData.Date),
          width: 1,
          zIndex: 5,
        },
      ],
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          color: TextColor,
        },
      },
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillOpacity: 0.6,
      },
      series: {
        // animation: true,
        marker: {
          enabled: true,
          radius: 2,
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: TextColor,
      },
    },
    series: series,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default SingleAreaChart;
