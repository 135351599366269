import "react-datetime/css/react-datetime.css";
import React, { useEffect, useState, Fragment } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiStopCircleOutline, mdiVolumeHigh } from "@mdi/js";
import { useLocation } from "react-router-dom";
import { getVinList } from "../../../redux/action/action";
import { useDispatch } from "react-redux";
import { atom } from "jotai";
import moment from "moment";
import { UseContextData } from "../../../context/dateContext";
import { Dialog, Transition } from "@headlessui/react";
import { UseManageData } from "../../../context/manageData";
import ToggleSwitch from "../../switch/ToggleSwitch";
import { useTranslation } from "react-i18next";
import baseUrl from "../../../util/baseUrl";
import axios from "axios";
export const VehicleId = atom("");
export const DefaultFleetName = atom("midwest");

const Header = ({ children, selectedMenu }) => {
  const {
    date,
    setDate,
    search,
    setSearch,
    selectedVin,
    setSelectedVin,
    fleetName,
    setfleetName,
    formatDate,
    setFormatDate,
    isDarkTheme,
    selectedLanguage,
    setSelectedLanguage,
    setselectedTimeStamp,
    selectedTimeStamp,
    selectedButton,
    setSelectedButton,
  } = UseContextData();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedVehicleId = queryParams.get("selectedVin");
  const [defaultDate, setDefaultDate] = useState(date);
  const [vinList, setVinList] = useState({});
  const [open, setOpen] = useState(false);
  const vinListArray = Object.values(vinList);
  const [selectedFleet, setSelectedFleet] = useState("midwest");
  const [selectFrequency, setSelectFrequency] = useState("Set Frequency");
  // const currentDate = new Date()
  // const [formatDate, setFormatDate] = useState(moment(currentDate).format('D MMM h:mm A'));
  const routePath = [
    "/fleet_view",
    "/fleet_view_map",
    "/digital_twins",
    "/fuel_net",
    "/canvas",
    "/econet",
    "/batterynet",
    "/",
  ];
  const [selectedMenuOption, setSelectedMenuOption] = useState("Real Time");
  const { stateData } = UseManageData();
  const { t } = useTranslation();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioObject, setAudioObject] = useState();
  const [snapshotData, setSnapshotData] = useState(null);

  const handleVehicleChange = (vin) => {
    setSelectedVin(vin);
  };

  const handleDateChange = (newDate) => {
    const formattedDate = moment(newDate).format("YYYY-MM-DD");
    setDate(formattedDate);
    // setDefaultDate(formattedDate);
  };
  const fleetOptions = [
    {
      label: t("Midwest"),
      value: t("midwest"),
    },
  ];

  const frequencyOptions = [
    {
      label: t("Set Frequency"),
      value: t("Set Frequency"),
    },
  ];

  useEffect(() => {
    setSelectedVin(selectedVehicleId || "TGdOtwPPBvxoRHV");
    // setDefaultDate("2022-01-01");
    setDate("2022-01-01");
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await dispatch(getVinList());
        const vinData = response?.payload?.data?.vin;

        if (vinData) {
          setVinList(vinData);
        }
      } catch (error) {
        console.error("Error fetching vinList:", error);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedDate = moment().format("D MMM h:mm A");
      setFormatDate(updatedDate);
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  const languages = [
    { code: "en", lang: "English" },
    { code: "fr", lang: "French" },
    { code: "hi", lang: "Hindi" },
    { code: "ar", lang: "Arabic" },
  ];

  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const text = `Take Action! ${stateData?.VehiclesNeedAttention} vehicle need attention. ${stateData?.safety} vehicles might have a safety issue. ${stateData?.Tire_issue} vehicles will need new tires next week. ${stateData?.Batteries} vehicles will need preventive battery maintenance next month. ${stateData?.efficiencyIssue} vehicles can improve its efficiency. ${stateData?.driverHabitIssue} drivers improved their habits, resulting in a 3% efficiency gain for your fleet`;

  const speak = async () => {
    if (isSpeaking) {
      stopAudio();
    }
    setIsSpeaking(true);
    setSnapshotData(stateData);
    try {
      const response = await axios.get(
        `${baseUrl}/text_to_speech?text=${encodeURIComponent(
          text
        )}&language=${selectedLanguage}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "audio/mp3" });
      const blobUrl = URL.createObjectURL(blob);
      const audio = new Audio(blobUrl);
      audio.onended = () => {
        setIsSpeaking(false);
      };
      audio.play();
      setAudioObject(audio);
    } catch (err) {
      console.log("Error converting text to speech", err);
    }
  };
  const stopAudio = () => {
    if (audioObject) {
      audioObject.pause();
      audioObject.currentTime = 0;
      setIsSpeaking(false);
      setAudioObject(null);
    }
  };

  useEffect(() => {
    if (!open) {
      stopAudio();
    }
  }, [open]);

  const displayedData = isSpeaking ? snapshotData : stateData;

  if (!displayedData) {
    return null;
  }

  const handleCheckboxChange = (checkboxName) => {
    setSelectedButton((prevState) => {
      if (prevState.includes(checkboxName)) {
        return prevState.filter((item) => item !== checkboxName);
      } else {
        return [...prevState, checkboxName];
      }
    });
  };

  const translatedTimeStamp =
    selectedTimeStamp === "daily"
      ? t("timeStamps.daily")
      : selectedTimeStamp === "weekly"
      ? t("timeStamps.weekly")
      : selectedTimeStamp === "monthly"
      ? t("timeStamps.monthly")
      : t("timeStamps.default");

  return (
    <div className="flex justify-between items-center ">
      <div className="flex w-full justify-between items-center gap-4">
        <div className="flex items-center gap-4">
          {routePath[4].includes(location.pathname) ||
          routePath[0].includes(location.pathname) ||
          routePath[5].includes(location.pathname) ? null : (
            <Menu placement="bottom-start">
              <MenuHandler>
                <div className="py-3 px-2 m in-w-[200px] h-10 bg-white dark:bg-[#444444] dark:text-white border border-primary rounded-lg flex items-center justify-between cursor-pointer">
                  <div className="text-sm">{selectedVin || "Vehicle Id"}</div>
                  {/* <img src='/assets/icons/ChevronDown.png' className='ml-2 h-4' /> */}
                  <Icon
                    path={mdiChevronDown}
                    size={"25px"}
                    className="ml-2 h-4 text-dark1 dark:text-white"
                  />
                </div>
              </MenuHandler>
              <MenuList className="dark:bg-[#444444] dark:text-white dark:border-primary">
                {vinListArray?.map((vinItem, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleVehicleChange(vinItem)}
                    value={vinItem}
                  >
                    {vinItem}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}
          {routePath[2].includes(location.pathname) ||
          routePath[0].includes(location.pathname) ? (
            <></>
          ) : (
            <div className="relative">
              <input
                type="text"
                className="border h-10 bg-white  dark:bg-[#444444] dark:text-white max-w-[168px] w-full border-primary px-4 py-2 outline-none rounded-lg placeholder:text-black dark:placeholder:text-white placeholder:text-xs"
                placeholder={t("Search")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <img
                src="/assets/icons/search.svg"
                className="absolute top-1/2 -translate-y-1/2 right-4"
              />
            </div>
          )}
          {/* {
          // routePath[0].includes(location.pathname) ? <></> :
            <Datetime dateFormat="YYYY-MM-DD" onChange={handleDateChange} initialValue={date} className="border  bg-white max-w-[150px] w-full border-primary  outline-none rounded-lg placeholder:text-dark placeholder:text-xs" timeFormat={false} closeOnSelect={true} />
        } */}
          {routePath[0].includes(location.pathname) ||
          routePath[2].includes(location.pathname) ? (
            <>
              <div>
                <div className="border h-10 bg-white dark:bg-[#444444] dark:text-white max-w-[168px] w-full border-primary px-4 py-2 outline-none rounded-lg placeholder:text-dark placeholder:text-xs">
                  {formatDate}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div>
            <Menu>
              <MenuHandler>
                <div className="py-4 px-2 h-10 bg-white dark:bg-[#444444] dark:text-white border border-primary rounded-lg flex items-center justify-between cursor-pointer">
                  <div className="text-sm">{t(selectedFleet)}</div>
                  {/* <img src='/assets/icons/ChevronDown.png' className='ml-2 h-4' /> */}
                  <Icon
                    path={mdiChevronDown}
                    size={"25px"}
                    className="ml-2 h-4 text-dark1 dark:text-white"
                  />
                </div>
              </MenuHandler>
              <MenuList className="dark:bg-[#444444] dark:text-white dark:border-primary">
                {fleetOptions?.map((fleetItem, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setSelectedFleet(fleetItem.value);
                    }}
                  >
                    {fleetItem.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </div>
          {routePath[5].includes(location.pathname) ? (
            <Menu>
              <MenuHandler>
                <div className="py-4 px-2 h-10 bg-white border dark:bg-[#444444] dark:text-white border-primary rounded-lg flex items-center justify-between cursor-pointer">
                  <div className="text-sm">{t(selectFrequency)}</div>
                  {/* <img src='/assets/icons/ChevronDown.png' className='ml-2 h-4' /> */}
                  <Icon
                    path={mdiChevronDown}
                    size={"25px"}
                    className="ml-2 h-4 text-dark1 dark:text-white"
                  />
                </div>
              </MenuHandler>
              <MenuList className="dark:bg-[#444444] dark:text-white dark:border-primary">
                {frequencyOptions?.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setSelectFrequency(item.value);
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          ) : (
            <></>
          )}
          {routePath[4].includes(location.pathname) ||
          routePath[5].includes(location.pathname) ? null : (
            <Menu>
              <MenuHandler>
                <div className="py-3 px-2 h-10 bg-white dark:bg-[#444444] dark:text-white border border-primary rounded-lg flex items-center justify-between cursor-pointer">
                  <div className="text-sm">{t(selectedMenuOption)}</div>
                  {/* <img src='/assets/icons/ChevronDown.png' className='ml-2 h-4' /> */}
                  <Icon
                    path={mdiChevronDown}
                    size={"25px"}
                    className="ml-2 h-4 text-dark1 dark:text-white"
                  />
                </div>
              </MenuHandler>
              <MenuList className="dark:bg-[#444444] dark:text-white dark:border-primary">
                <MenuItem
                  onClick={() => {
                    setSelectedMenuOption("Real Time");
                    selectedMenu("Real Time");
                  }}
                  selected={selectedMenuOption === "Real Time"}
                >
                  {t("Real Time")}
                </MenuItem>
                {/* <MenuItem
                  onClick={() => {
                    setSelectedMenuOption("Monthly");
                    selectedMenu("Monthly");
                  }}
                  selected={selectedMenuOption === "Monthly"}
                >
                  {t('Monthly')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedMenuOption("Weekly");
                    selectedMenu("Weekly");
                  }}
                  selected={selectedMenuOption === "Weekly"}
                >
                  {t('Weekly')}
                </MenuItem> */}
              </MenuList>
            </Menu>
          )}
          {/* {routePath[4].includes(location.pathname) ? (
            <Menu>
              <MenuHandler>
                <div className="py-3 px-2 h-10 bg-white dark:bg-[#444444] dark:text-white border border-primary rounded-lg flex items-center justify-between cursor-pointer">
                  <div className="text-sm">{t("Top 3 Efficient Tech")}</div>
                  <Icon
                    path={mdiChevronDown}
                    size={"25px"}
                    className="ml-2 h-4 text-dark1 dark:text-white"
                  />
                </div>
              </MenuHandler>
              <MenuList className="dark:bg-[#444444] dark:text-white dark:border-primary">
                <MenuItem>{t("Top 1")}</MenuItem>
                <MenuItem>{t("Top 2")}</MenuItem>
                <MenuItem>{t("Top 3")}</MenuItem>
              </MenuList>
            </Menu>
          ) : null} */}
          {routePath[4].includes(location.pathname) ? (
            <>
              {/* <Menu>
                <MenuHandler>
                  <div className="py-3 px-2 h-10 bg-white dark:bg-[#444444] dark:text-white border border-primary rounded-lg flex items-center justify-between cursor-pointer">
                    <div className="text-sm">{t("Last 3 Efficient Tech")}</div>
                    <Icon
                      path={mdiChevronDown}
                      size={"25px"}
                      className="ml-2 h-4 text-dark1 dark:text-white"
                    />
                  </div>
                </MenuHandler>
                <MenuList className="dark:bg-[#444444] dark:text-white dark:border-primary">
                  <MenuItem>{t("Last 1")}</MenuItem>
                  <MenuItem>{t("Last 2")}</MenuItem>
                  <MenuItem>{t("Last 3")}</MenuItem>
                </MenuList>
              </Menu> */}
              <Menu>
                <MenuHandler>
                  <div className="py-3 px-2 h-10 bg-white dark:bg-[#444444] dark:text-white border border-primary rounded-lg flex items-center justify-between cursor-pointer">
                    <div className="text-sm">{translatedTimeStamp}</div>
                    <Icon
                      path={mdiChevronDown}
                      size={"25px"}
                      className="ml-2 h-4 text-dark1 dark:text-white"
                    />
                  </div>
                </MenuHandler>
                <MenuList className="dark:bg-[#444444] dark:text-white dark:border-primary">
                  <MenuItem onClick={() => setselectedTimeStamp("daily")} >{t("Daily")}</MenuItem>
                  <MenuItem onClick={() => setselectedTimeStamp("weekly")} >{t("Weekly")}</MenuItem>
                  <MenuItem onClick={() => setselectedTimeStamp("monthly")} >{t("Monthly")}</MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : null}
          <div>
            <Menu>
              <MenuHandler>
                <div className="py-3 px-2 h-10 bg-white dark:bg-[#444444] dark:text-white border border-primary rounded-lg flex items-center justify-between cursor-pointer">
                  <div className="text-sm">{selectedLanguage}</div>
                  {/* <img src='/assets/icons/ChevronDown.png' className='ml-2 h-4' /> */}
                  <Icon
                    path={mdiChevronDown}
                    size={"25px"}
                    className="ml-2 h-4 text-dark1 dark:text-white"
                  />
                </div>
              </MenuHandler>
              <MenuList className="dark:bg-[#444444] dark:text-white dark:border-primary">
                <MenuItem
                  onClick={() => {
                    changeLanguage("en");
                    setSelectedLanguage("English");
                    // localStorage.setItem("Language","English")
                  }}
                  // selected={selectedLanguage === "English"}
                >
                  English
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeLanguage("es");
                    setSelectedLanguage("Spanish");
                    // localStorage.setItem("Language","Spanish")
                  }}
                  // selected={selectedLanguage === "Spanish"}
                >
                  Spanish
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeLanguage("zh");
                    setSelectedLanguage("Chinese");
                    // localStorage.setItem("Language","Chinese")
                  }}
                  // selected={selectedLanguage === "Chinese"}
                >
                  Chinese
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeLanguage("pt");
                    setSelectedLanguage("Portuguese");
                    // localStorage.setItem("Language","Portuguese")
                  }}
                  // selected={selectedLanguage === "Portuguese"}
                >
                  Portuguese
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
        {/* {routePath[6].includes(location.pathname) ? (
            <div className="flex items-center">
              <img src="/assets/images/profile.svg" className="rounded-full mr-2" />
              <div className="text-xl">Name Surname</div>
            </div>
      ) : null} */}
        <div className="flex items-center gap-10">
          <div>
            {" "}
            <ToggleSwitch />{" "}
          </div>
          {routePath[4].includes(location.pathname) ? (
            <IoCloseCircleOutline className="cursor-pointer text-[32px] text-[#5DA3E9]" />
          ) : (
            <div className="flex items-center">
              {/* <div className="mr-16 flex items-center">
              <img src="/assets/images/profile.svg" className="rounded-full mr-2" />
              <div className="text-xl">Name Surname</div>
            </div> */}
              <div className="flex justify-between items-center border border-[#5DA3E9] rounded-md relative bg-[#5DA3E9] shadow-[0px_4px_6px_0px_rgba(242,101,56,0.10)]">
                <div className="absolute flex items-center justify-center -left-6 top-1/2 w-10 h-10  -translate-y-1/2 bg-[#fff]  rounded-full">
                  <img
                    src="/assets/icons/Modo_de_aislamiento.png"
                    alt="warning"
                    className="w-6 h-6"
                  />
                </div>
                <button
                  className="bg-[#5DA3E9] pl-6 text-sm text-white p-3 rounded-md -mr-px"
                  onClick={() => setOpen(true)}
                >
                  {t("Summary")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-[8px] bg-white dark:bg-[#222222] text-left shadow-xl transition-all sm:my-8 w-full sm:w-[50%] sm:p-[24px_24px_30px_24px] ">
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-[13px]">
                        {/* <img src='/assets/icons/notification-bing.svg' alt="notification" /> */}
                        {isDarkTheme ? (
                          <img
                            src="/assets/icons/notification-bing-red.svg"
                            alt="notification-red"
                          />
                        ) : (
                          <img
                            src="/assets/icons/notification-bing.svg"
                            alt="notification"
                          />
                        )}
                        <div>
                          <h1 className="font-bold text-[20px] dark:text-white">
                            {t("Take Action!")}
                          </h1>
                          <p className="text-[#9b9696] dark:text-[#E4232F]">
                            {displayedData.VehiclesNeedAttention}{" "}
                            {t("vehicle need attention")}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-14">
                          {isSpeaking ? (
                            <Icon
                              path={mdiStopCircleOutline}
                              size={"25px"}
                              className="dark:text-white cursor-pointer"
                              onClick={stopAudio}
                            />
                          ) : (
                            <Icon
                              path={mdiVolumeHigh}
                              size={"25px"}
                              className="dark:text-white cursor-pointer"
                              onClick={() => speak()}
                            />
                          )}
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="flex justify-between items-center border border-[#5DA3E9] rounded-md relative bg-[#5DA3E9] shadow-[0px_4px_6px_0px_rgba(242,101,56,0.10)]">
                            <div className="absolute flex items-center justify-center -left-6 top-1/2 w-10 h-10  -translate-y-1/2 bg-[#fff]  rounded-full">
                              <img
                                src="/assets/icons/list_icon.svg"
                                alt="warning"
                                className="w-6 h-6"
                              />
                            </div>

                            {/* <div className='py-2 pl-6 pr-3 flex-grow text-dark text-sm'>{displayedData.VehiclesNeedAttention} Vehicles need attention now</div> */}
                            <button
                              className="bg-[#5DA3E9] pl-6 text-sm text-white p-3 rounded-md -mr-px"
                              onClick={() => setOpen(true)}
                            >
                              {t("Summary")}
                            </button>
                          </div>
                          <div className="flex items-center gap-[15px]">
                            {/* <div className='w-full flex justify-between items-center border border-[#F26538] rounded-md relative bg-light-pattern shadow-[0px_4px_6px_0px_rgba(242,101,56,0.10)]'>
                          <div className='absolute flex items-center justify-center -left-[10px] top-1/2 -translate-y-1/2 bg-alert-pattern w-5 h-5 rounded-full'>
                            <img src='/assets/icons/warning.svg' alt="warning" className='w-3' />
                          </div>
                          <div className='py-2 pl-6 pr-3 flex-grow text-dark text-sm'>{displayedData.VehiclesNeedAttention} Vehicle need attention now</div>
                          <button className='bg-alert-pattern rounded-md text-sm text-white p-[10px] -mr-px'>Take action</button>
                        </div> */}
                            <IoCloseCircleOutline
                              onClick={() => setOpen(false)}
                              className="cursor-pointer text-[30px] dark:text-white"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <div className='mt-[10px] flex items-center gap-[10px]' >
                        <div className="relative">
                          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                          </div>
                          <input type="search" id="default-search" className="block  px-[40px] py-[5px] ps-10 text-sm w-[660px] text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-0 focus:outline-none focus:!border-[#EAEBF0] " placeholder="Search" required />
                        </div>
                        <div className='border border-red-500  py-[5px] px-[10px] rounded-lg  flex items-center gap-[5px] shadow-lg' >
                          <button className='text-[#ccc]'>Select All</button>
                          <div className='p-1 bg-[#ccc] rounded-full w-5 h-5'></div>
                        </div>
                      </div> */}
                      <div className="flex mt-[30px] justify-between items-center">
                        <div className="flex items-center gap-[30px] w-full">
                          <div className=" mb-[10px]">
                            <img
                              src="/assets/icons/car_icon.svg"
                              alt="239567"
                              className="w-[51px] min-w-[51px] h-[51px]"
                            />
                          </div>
                          <div className="flex justify-between items-center w-full border-b border-b-[#f2efef] pb-[10px]">
                            <div className="">
                              <h1 className="font-normal text-[17px] text-[#000] dark:text-white">
                                {displayedData?.safety}{" "}
                                {t("vehicles might have a safety issue")}
                              </h1>
                              <p className="text-[#9b9696] my-[7px] dark:text-[#E4232F]">
                                {t("Notify driver!")}
                              </p>
                            </div>
                            <button className="max-w-[115px] twrap px-[10px] py-[5px] bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative">
                              {t("Take Action")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-[30px] justify-between items-center">
                        <div className="flex items-center gap-[30px] w-full">
                          <div className=" mb-[10px]">
                            <img
                              src="/assets/icons/wheel.svg"
                              alt="239568"
                              className="w-[51px] min-w-[51px] h-[51px]"
                            />
                          </div>
                          <div className="flex justify-between items-center w-full border-b border-b-[#f2efef] pb-[10px]">
                            <div>
                              <h1 className="font-normal text-[17px] text-[#000] dark:text-white">
                                {displayedData?.Tire_issue}{" "}
                                {t("vehicles will need new tires next week")}
                              </h1>
                              <p className="text-[#9b9696] my-[7px] dark:text-[#E4232F]">
                                {t("Notify driver!")}
                              </p>
                            </div>
                            <button className="max-w-[115px] twrap px-[10px] py-[5px] bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative">
                              {t("Take Action")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-[30px] justify-between items-center">
                        <div className="flex items-center gap-[30px] w-full">
                          <div className=" mb-[10px]">
                            <img
                              src="/assets/icons/Group 33-3.svg"
                              alt="33"
                              className="w-[51px] min-w-[51px] h-[51px]"
                            />
                          </div>
                          <div className="flex justify-between items-center w-full border-b border-b-[#f2efef] pb-[10px]">
                            <div>
                              <h1 className="font-normal text-[17px] text-[#000] dark:text-white">
                                {displayedData?.Batteries}{" "}
                                {t(
                                  "vehicles will need preventive battery maintenance next month"
                                )}
                              </h1>
                              <p className="text-[#9b9696] my-[7px] dark:text-[#E4232F]">
                                {t("Notify driver!")}
                              </p>
                            </div>
                            <button className="max-w-[115px] twrap px-[10px] py-[5px] bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative">
                              {t("Take Action")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-[30px] justify-between items-center">
                        <div className="flex items-center gap-[30px] w-full">
                          <div className=" rounded-full mb-[10px]">
                            <img
                              src="/assets/icons/graph_icon.svg"
                              alt="239565"
                              className="w-[51px] min-w-[51px] h-[51px]"
                            />
                          </div>
                          <div className="flex justify-between items-center w-full border-b border-b-[#f2efef] pb-[10px]">
                            <div>
                              <h1 className="font-normal text-[17px] text-[#000] dark:text-white">
                                {displayedData?.efficiencyIssue}{" "}
                                {t("vehicles can improve its efficiency")}
                              </h1>
                              <p className="text-[#9b9696] my-[7px] dark:text-[#EBAE1B]">
                                {t("improve now!!")}
                              </p>
                            </div>
                            <button className="max-w-[115px] twrap px-[10px] py-[5px] bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative">
                              {t("Take Action")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-[30px] justify-between items-center">
                        <div className="flex items-center gap-[30px] w-full">
                          <div className=" rounded-full mb-[10px]">
                            <img
                              src="/assets/icons/Handshake.svg"
                              alt="239566"
                              className="w-[51px] min-w-[51px] h-[51px]"
                            />
                          </div>
                          <div className="flex justify-between items-center w-full border-b border-b-[#f2efef] pb-[10px]">
                            <div>
                              <h1 className="font-normal text-[17px] text-[#000] dark:text-white">
                                {displayedData?.driverHabitIssue}{" "}
                                {t(
                                  "drivers improved their habits, resulting in a 3% efficiency gain for your fleet"
                                )}
                              </h1>
                              <p className="text-[#9b9696] my-[7px] dark:text-[#EBAE1B]">
                                {t("Advice drivers now!")}
                              </p>
                            </div>
                            <button className="max-w-[115px] twrap px-[10px] py-[5px] bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative">
                              {t("Take Action")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div>{children}</div>
    </div>
  );
};

export default Header;
