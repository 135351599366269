import React, { useEffect, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getFleetChartData } from '../../../redux/action/action';
import { useDispatch } from 'react-redux';
import { UseContextData } from '../../../context/dateContext';
import { format, addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ActiveVehicleChart = ({ graphSelection, isDarkTheme }) => {
  const [selectedGraph, setselectedGraph] = useState(graphSelection);
  const [chartdata, setchartData] = useState();
  const [dynamicLabels, setDynamicLabels] = useState([]);
  const dispatch = useDispatch();
  const dataIntervalRef = useRef(null);
  const { formatDate } = UseContextData()
  const TextColor = isDarkTheme ? "#ffffff" : "gray";
  const backgroundColor = isDarkTheme ? "#0e1728" : "#ffffff";
  const { t } = useTranslation()

  // Function to generate dynamic GMT time labels
  // const generateTimeLabels = (length) => {
  //   const formatDate = new Date(); // Get the current time
  //   const previousTimes = Array.from({ length }, (_, index) => {
  //     const gmtTime = addMinutes(formatDate, -index).toLocaleTimeString('en-US', {
  //       timeZone: 'GMT', 
  //       hour12: false, 
  //       hour: '2-digit',
  //       minute: '2-digit',
  //     });
  //     return gmtTime;
  //   });
  //   return previousTimes.reverse(); // Reverse the array to display in ascending order
  // };


  const generateTimeLabels = (length) => {
    const previousTimes = Array.from({ length }, (_, index) => format(addMinutes(formatDate, -index), 'hh:mm'));
    return previousTimes.reverse();
  };

  useEffect(() => {
    const getchartData = async () => {
      const requestParams = { field_name: selectedGraph };
      try {
        const response = await dispatch(getFleetChartData(requestParams));
        if (response.payload.data && response.payload.data.length > 0) {
          const responseData = response.payload.data;
          setchartData(responseData);
          // setDynamicLabels(responseData?.map((_, index) => index));
          setDynamicLabels(generateTimeLabels(responseData.length));
        }
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    };
    getchartData()
    dataIntervalRef.current = setInterval(() => {
      getchartData();
    }, 30000);
    return () => {
      if (dataIntervalRef.current) {
        clearInterval(dataIntervalRef.current);
      }
    };
  }, [selectedGraph, dispatch, formatDate])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: TextColor
        },
        ticks: {
          color: TextColor
        },
        border: {
          display: false
        },
      },
      y: {
        grid: {
          display: true,
          // color:'gray'
          color: TextColor
        },
        ticks: {
          color: TextColor,
          beginAtZero: true,
          stepSize: 1,
        },
        gridLines: {
          display: true,
          drawBorder: false,
        },
      }
    },
  };

  const graphLabels = {
    "ActiveVehicles": t('Active Vehicles'),
    "Cost per mile": t('Cost per mile'),
    "Mileage": t('Miles per gallon'),
    "No_of_Jobs_done": t('Number of jobs'),
    "Fuel Consumed": t('Fuel'),
    "Energy(KJ)": t('Energy'),
    "CO2 Emission (Kg)": t('Emissions')
  };

  const graphUnits = {
    "Cost per mile": t('(cents)'),
    "Energy(KJ)": t('(KJ)'),
  };

  const data = {
    labels: dynamicLabels,
    datasets: [
      {
        label:graphLabels[selectedGraph] || "",
        data: chartdata,
        borderColor: '#1ab394',
        borderWidth: 1,
        backgroundColor: '#1ab394',
      },
    ],
  };
  return (
    <div>
      <h4 className="dark:text-white">{graphLabels[selectedGraph]}</h4>
      <h2 className="dark:text-white">{graphUnits[selectedGraph] || ""}</h2>
      <Line height={"150px"} options={options} data={data} />
    </div>
  )
}

export default ActiveVehicleChart
