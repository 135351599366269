import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../util/baseUrl";

export const getVinList = createAsyncThunk("get/data", async () => {
  const response = await axios.get(`${baseUrl}/vin/list`);
  return response?.data;
});

export const getVehicleInfo = createAsyncThunk("get/data", async ({ vin }) => {
  const response = await axios.get(`${baseUrl}/econet/vin/info?vin=${vin}`);
  return response?.data;
});

export const getMapData = createAsyncThunk("get/data", async ({ date }) => {
  const response = await axios.get(
    `${baseUrl}/fleetoverview/fleet_latlong?date=${date}`
  );
  return response?.data;
});

export const getAllVehicles = createAsyncThunk(
  "get/data",
  async ({ fleet, date }) => {
    const response = await axios.get(
      `${baseUrl}/fleetoverview/vehicle_list?fleet=${fleet}&date=${date}`
    );
    return response?.data;
  }
);

export const getFleetOverviewData = createAsyncThunk("get/data", async () => {
  const response = await axios.get(`${baseUrl}/fleet_overview`);
  return response?.data;
});

export const getFleetOverviewDataByIndex = createAsyncThunk(
  "get/data",
  async (index) => {
    const response = await axios.get(
      `${baseUrl}/fleet_overview_by_index/${index}`
    );
    return response?.data;
  }
);

export const getFleetChartData = createAsyncThunk(
  "get/data",
  async ({ field_name }) => {
    const response = await axios.get(
      `${baseUrl}/fleet_overview_chart?field_name=${field_name}`
    );
    return response?.data;
  }
);

export const getTripLocation = createAsyncThunk(
  "get/data",
  async ({ vin, date }) => {
    const response = await axios.get(
      `${baseUrl}/econet/map/location?vin=${vin}&date=${date}`
    );
    return response?.data;
  }
);

export const getWeatherData = createAsyncThunk(
  "get/data",
  async ({ latitude, longitude, date }) => {
    const response = await axios.get(
      `${baseUrl}/econet/weather?latitude=${latitude}&longitude=${longitude}&date=${date}`
    );
    return response?.data;
  }
);

export const getTiresStatus = createAsyncThunk("get/data", async ({ vin }) => {
  const response = await axios.get(`${baseUrl}/tire_status?vin=${vin}`);
  return response?.data;
});

export const getTiresStatusFlag = createAsyncThunk(
  "get/data",
  async ({ vin }) => {
    const response = await axios.get(`${baseUrl}/tire/flag?vin=${vin}`);
    return response?.data;
  }
);

export const getTiresStatusNotifications = createAsyncThunk(
  "get/data",
  async ({ vin }) => {
    const response = await axios.get(`${baseUrl}/tire/notification?vin=${vin}`);
    return response?.data;
  }
);

export const getTiresAvg = createAsyncThunk("get/data", async ({ vin }) => {
  const response = await axios.get(`${baseUrl}/tire/average?vin=${vin}`);
  return response?.data;
});

export const getSeeTrendData = createAsyncThunk(
  "get/data",
  async ({ field_name, canvas }) => {
    const response = await axios.get(
      `${baseUrl}/canvas_see_trend?field_name=${field_name}&canvas=${canvas}`
    );
    return response?.data;
  }
);

export const getForecastingData = createAsyncThunk(
  "get/data",
  async ({ field_name, canvas, index }) => {
    const response = await axios.get(
      `${baseUrl}/forecasting_chart/${index}?field_name=${field_name}&canvas=${canvas}`
    );
    return response?.data;
  }
);

export const getMultivariateData = createAsyncThunk(
  "get/data",
  async ({ field_name, canvas, index }) => {
    const response = await axios.get(
      `${baseUrl}/multivariate_analysis/${index}?field_name=${field_name}&canvas=${canvas}`
    );
    return response?.data;
  }
);

export const getEconetData = createAsyncThunk("get/data", async ({ index }) => {
  const response = await axios.get(`${baseUrl}/econet_data/${index}`);
  return response?.data;
});

export const getEconetChartData = createAsyncThunk(
  "get/data",
  async ({ index, params }) => {
    const response = await axios.get(
      `${baseUrl}/econet_chart_data/${index}?params=${params}`
    );
    return response?.data;
  }
);

export const getBatteryNetData = createAsyncThunk(
  "get/data",
  async ({ vehicleId }) => {
    const response = await axios.get(
      `${baseUrl}/batterynet_overview?vehicle_id=${vehicleId}`
    );
    return response?.data;
  }
);

export const getBatteryChargeData = createAsyncThunk(
  "get/data",
  async ({ index }) => {
    const response = await axios.get(`${baseUrl}/battery_charge/${index}`);
    return response?.data;
  }
);

export const getBatteryTempratureData = createAsyncThunk(
  "get/data",
  async ({ index }) => {
    const response = await axios.get(`${baseUrl}/battery_temprature/${index}`);
    return response?.data;
  }
);

export const registerUser = createAsyncThunk("get/data", async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/register`, data);
    return response;
  } catch (error) {
    return error?.response;
  }
});

export const loginUser = createAsyncThunk("get/data", async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/login`, data);
    return response;
  } catch (error) {
    return error?.response;
  }
});

export const forgetPassword = createAsyncThunk("get/data", async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/forget_password`, data);
    return response;
  } catch (error) {
    return error?.response;
  }
});

export const resetPassword = createAsyncThunk(
  "get/data",
  async ({ data, token }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/reset_password/${token}`,
        data
      );
      return response;
    } catch (error) {
      return error?.response;
    }
  }
);

export const subscribeUser = createAsyncThunk("get/data", async (data) => {
  try {
    const response = await axios.post(`${baseUrl}/send_email`, data);
    return response;
  } catch (error) {
    return error?.response;
  }
});

const createReducer = createSlice({
  name: "post",
  initialState: {
    initialData: [],
    loggedInUser: [],
    loading: false,
    error: null,
  },
  extraReducers: {
    [getVinList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVinList.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getVinList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getVehicleInfo.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVehicleInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getVehicleInfo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getMapData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getMapData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getMapData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getAllVehicles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getAllVehicles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getFleetOverviewData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getFleetOverviewData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getFleetOverviewData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getFleetChartData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getFleetChartData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getFleetChartData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getTripLocation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTripLocation.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getTripLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getWeatherData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getWeatherData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getWeatherData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getFleetOverviewDataByIndex.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getFleetOverviewDataByIndex.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getFleetOverviewDataByIndex.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getTiresStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTiresStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getTiresStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getTiresStatusFlag.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTiresStatusFlag.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getTiresStatusFlag.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getTiresStatusNotifications.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTiresStatusNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getTiresStatusNotifications.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getTiresAvg.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTiresAvg.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getTiresAvg.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getSeeTrendData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSeeTrendData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getSeeTrendData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getForecastingData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getForecastingData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getForecastingData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getMultivariateData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getMultivariateData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getMultivariateData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getEconetData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getEconetData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getEconetData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getEconetChartData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getEconetChartData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getEconetChartData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getBatteryNetData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBatteryNetData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getBatteryNetData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getBatteryChargeData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBatteryChargeData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getBatteryChargeData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getBatteryTempratureData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBatteryTempratureData.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [getBatteryTempratureData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedInUser = action.payload;
      state.error = null;
    },
    [registerUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [loginUser.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedInUser = action.payload;
      state.error = null;
    },
    [loginUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [forgetPassword.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [forgetPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedInUser = action.payload;
      state.error = null;
    },
    [forgetPassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [resetPassword.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedInUser = action.payload;
      state.error = null;
    },
    [resetPassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [subscribeUser.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [subscribeUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.initialData = action.payload;
      state.error = null;
    },
    [subscribeUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default createReducer.reducer;
