import { createContext, useContext, useState } from "react";

const manageData = createContext();

export const UseManageData = () => {
  return useContext(manageData)
}

const ManageAllData = ({ children }) => {

  const [stateData, setStateData] = useState({
    ActiveVehicles: 0,
    Batteries: 0,
    CO2_Emission: 0,
    CostPerMile: 0,
    Distance: 0,
    Efficiency: 0,
    EnergySaving: 0,
    Energy_KJ: 0,
    FuelConsumed: 0,
    LowBatteryVoltageVehicle: 0,
    LowTirePressureVehicle: 0,
    Mileage: 0,
    NoofJobsDone: 0,
    SUV: 0,
    Sedan: 0,
    Tire_issue: 0,
    Van: 0,
    VehiclesNeedAttention: 0,
    chanage_CPM: 0,
    change_ActiveVehicles: 0,
    change_CO2EmissionKg: 0,
    change_Efficiency: 0,
    change_EnergyKJ: 0,
    change_FuelConsumed: 0,
    change_LowBatteryVoltageVehicle: 0,
    change_LowTirePressureVehicle: 0,
    change_Mileage: 0,
    change_NoofJobsDone: 0,
    driverHabitIssue: 0,
    efficiencyIssue: 0,
    safety: 0,
    SuvIdletime: 0,
    VanIdletime: 0,
    SedanIdletime: 0,
    SpeedRecommendation: 0,
    SuvTirePressurePSI: 0,
    VanTirePressurePSI: 0,
    SedanTirePressurePSI: 0,
    suv_lower_speed_limit: 0,
    suv_upper_speed_limit: 0,
    pickup_lower_speed_limit: 0,
    pickup_upper_speed_limit: 0,
    sedan_lower_speed_limit: 0,
    sedan_upper_speed_limit: 0,
    suv_FrontTire_pressure_recom: 0,
    suv_RearTire_pressure_recom: 0,
    pickup_FrontTire_pressure_recom: 0,
    pickup_RealTire_pressure_recom: 0,
    sedan_FrontTire_pressure_recom: 0,
    sedan_RearTire_pressure_recom: 0,
    suv_idletime_recom: 0,
    pickup_idletime_recom: 0,
    sedan_idletime_recom: 0,
    Future_Red_percent: 0,
    Future_Green_percent: 0,
    Future_Yellow_percent: 0,
    Red_percent: 0,
    Green_percent: 0,
    Yellow_percent: 0,
    speed_issue: 0,
    Idling_issue: 0,
    LowTirePressure_issue: 0,
    Future_speed_issue: 0,
    Future_Idling_issue: 0,
    Future_LowTirePressure_issue: 0,
  });


  return (
    <manageData.Provider value={{ stateData, setStateData }}>
      {children}
    </manageData.Provider>
  )
}
export default ManageAllData;