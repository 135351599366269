import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const SuccessMessage = ({ open, setOpen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-[8px] bg-white dark:bg-[#252525] text-left shadow-xl transition-all sm:my-8 max-w-xl w-full sm:p-[24px_24px_30px_24px] ">
                  <div className="flex justify-end">
                    <button onClick={() => setOpen(false)}>
                      <IoCloseCircleOutline className="dark:text-white text-[25px]" />
                    </button>
                  </div>
                  <div className="flex justify-center">
                    <h1 className="font-semibold text-xl dark:text-white">
                      {t("Thank you for your response")}
                    </h1>
                  </div>
                  <div className="flex justify-center">
                    <h1 className="font-semibold text-xl dark:text-white">
                      {t("Our representative will contact you soon...")}
                    </h1>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default SuccessMessage;
