import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getEconetData } from '../redux/action/action';

const econetData = createContext();

export const UseEconetData = () => {
  return useContext(econetData)
}

const EconetAllData = ({ children }) => {

  const [stateData,setStateData] = useState()
  return (
    <econetData.Provider value={{ stateData, setStateData }}>
      { children }
    </econetData.Provider >
  )
}

export default EconetAllData
