import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { UseContextData } from "../../../context/dateContext";
import { subscribeUser } from "../../../redux/action/action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import SuccessMessage from "./SuccessMessage";

const SubscribeForm = ({ setOpenForm, openForm }) => {
  const { t } = useTranslation();

  const { user } = UseContextData();
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Name is required")),
    email: Yup.string()
      .email(t("Invalid email"))
      .required(t("Email is required")),
    organization_name: Yup.string().required(
      t("Organization name is required")
    ),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, t("Invalid number"))
      .required(t("Mobile number is required")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      organization_name: "",
      mobile_number: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await dispatch(subscribeUser(values));
        if (response?.payload?.status === 200) {
          setOpenForm(false);
          formik.resetForm();
          setShowSuccess(true);
        } else {
          toast.error(response?.payload?.data?.message);
          formik.resetForm();
        }
      } catch (error) {
        console.error("Submission failed", error);
      }
    },
  });

  useEffect(() => {
    if (!openForm) {
      formik.resetForm();
    }
  }, [openForm]);

  return (
    <>
      <Transition.Root show={openForm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => {
            setOpenForm(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-[8px] bg-white dark:bg-[#252525] text-left shadow-xl transition-all sm:my-8 max-w-xl w-full sm:p-[24px_24px_30px_24px] ">
                  <div className="flex justify-between">
                    <h5 className="mx-auto text-[18px] text-xl font-semibold text-gray-700 dark:text-[#fff]">
                      {t("Subscribe to AUTONET")}
                    </h5>
                    <button
                      onClick={() => {
                        setOpenForm(false);
                      }}
                    >
                      <IoCloseCircleOutline className="dark:text-white text-[25px]" />
                    </button>
                  </div>
                  <div>
                    <form
                      className="flex justify-between items-center flex-col"
                      onSubmit={formik.handleSubmit}
                    >
                      <div className="mt-[45px]">
                        <input
                          type="text"
                          className={`w-[100%] shadow-lg  h-[60px] rounded-md pl-[27px] bg-[#E8f0f8] dark:bg-[#444444] focus:outline-none hover:outline-none font-[600] placeholder:text-[#6FA2B0] text-[#6FA2B0] ${
                            formik.touched.name && formik.errors.name
                              ? "border-red-500"
                              : ""
                          }`}
                          placeholder={t("Name")}
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="text-red-500">
                            {formik.errors.name}
                          </div>
                        ) : null}

                        <input
                          type="email"
                          className={`w-[100%] shadow-lg  h-[60px] mt-[24px] rounded-md pl-[27px] bg-[#E8f0f8] dark:bg-[#444444] focus:outline-none hover:outline-none font-[600] placeholder:text-[#6FA2B0] text-[#6FA2B0] ${
                            formik.touched.email && formik.errors.email
                              ? "border-red-500"
                              : ""
                          }`}
                          placeholder={t("Email")}
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-500">
                            {formik.errors.email}
                          </div>
                        ) : null}

                        <div className="relative">
                          <input
                            type="text"
                            className={`relative shadow-lg bg-[#E8f0f8] dark:bg-[#444444] w-[465px] h-[60px] rounded-md mt-[24px] pl-[27px] pr-[100px] focus:outline-none hover:outline-none font-[600] placeholder:text-[#6FA2B0] text-[#6FA2B0] ${
                              formik.touched.organization_name &&
                              formik.errors.organization_name
                                ? "border-red-500"
                                : ""
                            }`}
                            placeholder={t("Organisation")}
                            name="organization_name"
                            value={formik.values.organization_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.organization_name &&
                          formik.errors.organization_name ? (
                            <div className="text-red-500">
                              {formik.errors.organization_name}
                            </div>
                          ) : null}
                        </div>

                        <input
                          type="text"
                          className={`w-[100%] shadow-lg  h-[60px] mt-[24px] dark:bg-[#444444] rounded-md pl-[27px] bg-[#E8f0f8] focus:outline-none hover:outline-none font-[600] placeholder:text-[#6FA2B0] text-[#6FA2B0] ${
                            formik.touched.mobile_number &&
                            formik.errors.mobile_number
                              ? "border-red-500"
                              : ""
                          }`}
                          placeholder={t("Phone Number")}
                          name="mobile_number"
                          value={formik.values.mobile_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.mobile_number &&
                        formik.errors.mobile_number ? (
                          <div className="text-red-500">
                            {formik.errors.mobile_number}
                          </div>
                        ) : null}
                      </div>

                      <button
                        className="mt-[43px] mx-auto bg-[#5DA3E9] rounded-lg  w-[187px] h-[50px] text-white text-lg font-bold"
                        type="submit"
                      >
                        {t("Submit")}
                      </button>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <SuccessMessage open={showSuccess} setOpen={setShowSuccess} />
    </>
  );
};
export default SubscribeForm;
