import React, { useEffect, useRef, useState } from "react";
import Layout from "../component/common/Layout/Layout";
import Header from "../component/common/Header/Header";
import AreaChart from "../component/econet/AreaChart";
import ChooseTime from "../component/econet/ChooseTime";
import { UseEconetData } from "../context/econetData";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import OdometerBox from "../component/econet/OdoMeter/Odometer";
import EfficiencyModal from "../component/common/popup/EfficiencyModal";
import { useDispatch } from "react-redux";
import { getEconetData } from "../redux/action/action";
import { useTranslation } from "react-i18next";
import { UseContextData } from "../context/dateContext";



const Econet = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false)
  const { stateData, setStateData } = UseEconetData();
  const { t } = useTranslation();
  const { selectedLanguage } = UseContextData();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const IndexValue = useRef(0);

  const dispatch = useDispatch()
  useEffect(() => {
    const loadData = async () => {
      const requestParams = {index: IndexValue.current};
      try {
        const response = await dispatch(getEconetData(requestParams));
        const responseData = response?.payload?.data;
        if (responseData) {
          setStateData(responseData);
          IndexValue.current = response?.payload?.next_index;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData()
    const intervalId = setInterval(() => {
      loadData();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [dispatch])

  const savingOptions = [
    {
      iconPath: "/assets/icons/dollar-green.svg",
      title: t("Cost Savings"),
    },
    {
      iconPath: "/assets/icons/fuel-orange.svg",
      title: t("Fuel Savings"),
    },
    {
      iconPath: "/assets/icons/energy-blue.svg",
      title: t("Energy Savings"),
    },
    {
      iconPath: "/assets/icons/co2-cyan.svg",
      title: t("Co2 Savings"),
    },
  ];

  return (
    <Layout>
      <div className="h-full">
        <Header selectedMenu={(selectedOption) => selectedOption} />
      </div>
      <div className="grid grid-cols-12 mt-5 gap-2">
        <div className="col-span-2 rounded-lg bg-white shadow-md bg-opacity-80 dark:bg-[#222222]">
          <div className="px-6 py-3.5 text-[#27403F] text-center text-xl mb-3 min-h-[62px] dark:text-white">
            {t('Saving')}
          </div>
          <div className="grid grid-cols-1 gap-3">
            {savingOptions.map((item, index) => {
              return (
                <div
                  className={`min-h-[185px] last:rounded-b-lg bg-white flex flex-col justify-center items-center dark:bg-[#444444]`}
                  key={index}
                >
                  <div className="mb-2">
                    <img src={item.iconPath} alt="fuel-orange" />
                  </div>
                  <div className="text-xl text-[#27403F] dark:text-white twrap">{item.title}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-span-10 ">
          <div className="grid grid-cols-3 bg-white rounded-lg mb-3 dark:bg-[#444444]">
            <div className="px-6 pt-1.5 pb-1 text-[#27403F] text-center dark:text-white">
              <div className="text-xl">{t('Real Time Savings')}</div>
              <ChooseTime />
            </div>
            <div className="px-6 pt-1.5 pb-1 text-[#27403F] text-center dark:text-white">
              <div className="text-xl">{t('Opportunities')}</div>
              <div className="cursor-pointer flex items-center text-sm justify-center">
                {t('In next 1000 miles')}{" "}
                <button className="text-white bg-[#5DA3E9] px-1 py-0.5 ml-1.5 rounded-md text-xs" onClick={() => setOpen(!open)}>
                  {t('Take Action!')}
                </button>
              </div>
            </div>
            <div className="px-6 pt-1.5 pb-1 text-[#27403F] text-center dark:text-white">
              <div className="text-xl">{t('Total Savings')}</div>
              <div className="cursor-pointer flex items-center justify-center text-sm">
                {t('Till Date')}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 min-h-[185px] bg-white p-3 pb-2.5 rounded-lg gap-7 mb-3 dark:bg-[#444444]">
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="border-[1px] shadow-xl flex rounded-lg p-3 dark:bg-[#363636] dark:border-0">
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/transport2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.VAN_money_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('USD')}</span>
                      </div>
                      {/* <div className="text-xs text-[#787F89]">dollars</div> */}
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('USD')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.VAN_money_saving_pct_change > 0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.VAN_money_saving_pct_change?.toFixed(3) ||
                            0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/car3.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.SEDAN_money_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('USD')}</span>
                      </div>
                      {/* <div className="text-xs text-[#787F89]">dollars</div> */}
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('USD')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.SEDAN_money_saving_pct_change > 0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.SEDAN_money_saving_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/tempo2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.PICKUPTRUCK_money_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('USD')}</span>
                      </div>
                      {/* <div className="text-xs text-[#787F89]">dollars</div> */}
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('USD')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.PICKUPTRUCK_money_saving_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.PICKUPTRUCK_money_saving_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[28px] text-[#27403F] dark:text-white mt-[10px]">
                {stateData?.Money_saving?.toFixed(2) || 0}{" "} 
                <span className="text-[#787F89] text-[12px] font-normal">
                  {t('USD')}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="border-[1px] shadow-xl flex rounded-lg p-3 dark:bg-[#363636] dark:border-0">
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/transport2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.VAN_money_saving_1000miles?.toFixed(2) || 0} {" "}<span className="text-[#787F89] text-[12px] font-normal">{t('USD')}</span>
                      </div>
                      {/* <div className="text-xs text-[#787F89]">dollars</div> */}
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('USD')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.VAN_money_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.VAN_money_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/car3.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F]  dark:text-white">
                        {stateData?.SEDAN_money_saving_1000miles?.toFixed(2) ||
                          0} {" "}<span className="text-[#787F89] text-[12px] font-normal">{t('USD')}</span>
                      </div>
                      {/* <div className="text-xs text-[#787F89]">dollars</div> */}
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('USD')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.SEDAN_money_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.SEDAN_money_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/tempo2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.PICKUPTRUCK_money_saving_1000miles?.toFixed(
                          2
                        ) || 0} {" "}
                        <span className="text-[#787F89] text-[12px] font-normal">{t('USD')}</span>
                      </div>
                      {/* <div className="text-xs text-[#787F89]">dollars</div> */}
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('USD')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.PICKUPTRUCK_money_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.PICKUPTRUCK_money_saving_1000miles_pct_change?.toFixed(
                            2
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[28px] text-[#27403F] dark:text-white mt-[10px]">
                {stateData?.Money_saving_1000miles?.toFixed(2) || 0}{" "}
                <span className="text-[#787F89] text-[12px] font-normal">
                  {t('USD')}
                </span>
              </div>
            </div>
            <div className="w-full col-span-1 flex items-center justify-center">
              {/* <AreaChart type="money_saving" color="#85BB65" /> */}
              <OdometerBox
                currencyType={t("USD")}
                amount={stateData?.cumulative_money_saving?.toFixed(2) || 0}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 min-h-[185px] bg-white p-3 pb-2.5 rounded-lg gap-7 mb-3 dark:bg-[#444444]">
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="border-[1px] shadow-xl flex rounded-lg p-3 dark:bg-[#363636] dark:border-0">
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/transport2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.VAN_fuel_saving?.toFixed(2) || 0} {" "}<span className="text-[#787F89] text-[12px] font-normal">{t('gallons')}</span>
                      </div>
                      {/* <div className="text-xs text-[#787F89]">{t('gallons')}</div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.VAN_fuel_saving_pct_change > 0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.VAN_fuel_saving_pct_change?.toFixed(3) ||
                            0} 
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/car3.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.SEDAN_fuel_saving?.toFixed(2) || 0} {" "}<span className="text-[#787F89] text-[12px] font-normal">{t('gallons')}</span>
                      </div>
                      {/* <div className="text-xs text-[#787F89]">{t('gallons')}</div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.SEDAN_fuel_saving_pct_change > 0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.SEDAN_fuel_saving_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/tempo2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.PICKUPTRUCK_fuel_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('gallons')}</span>
                      </div>
                      {/* <div className="text-xs text-[#787F89]">{t('gallons')}</div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.PICKUPTRUCK_fuel_saving_pct_change > 0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.PICKUPTRUCK_fuel_saving_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[28px] text-[#27403F] dark:text-white mt-[10px]">
                {stateData?.Fuel_saving?.toFixed(2) || 0}{" "}
                <span className="text-[#787F89] text-[12px] font-normal">{t('gallons')}</span>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="border-[1px] shadow-xl flex rounded-lg p-3 dark:bg-[#363636] dark:border-0">
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/transport2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.VAN_fuel_saving_1000miles?.toFixed(2) || 0} {" "}<span className="text-[#787F89] text-[12px] font-normal">{t('gallons')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('gallons')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.VAN_fuel_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.VAN_fuel_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/car3.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.SEDAN_fuel_saving_1000miles?.toFixed(2) ||
                          0} {" "}<span className="text-[#787F89] text-[12px] font-normal">{t('gallons')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('gallons')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.SEDAN_fuel_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.SEDAN_fuel_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/tempo2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.PICKUPTRUCK_fuel_saving_1000miles?.toFixed(
                          2
                        ) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('gallons')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('gallons')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.PICKUPTRUCK_fuel_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.PICKUPTRUCK_fuel_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[28px] text-[#27403F] dark:text-white mt-[10px]">
                {stateData?.Fuel_saving_1000miles?.toFixed(2) || 0}{" "}
                <span className="text-[#787F89] text-[12px] font-normal">{t('gallons')}</span>
              </div>
            </div>
            <div className="w-full col-span-1 flex items-center justify-center">
              {/* <AreaChart type="fuel_saving" color="#FDCA40" /> */}
              <OdometerBox
                currencyType={t("gallons")}
                amount={stateData?.cumulative_fuel_saving?.toFixed(2) || 0}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 min-h-[185px] bg-white p-3 pb-2.5 rounded-lg gap-7 mb-3 dark:bg-[#444444]">
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="border-[1px] shadow-xl flex rounded-lg p-3 dark:bg-[#363636] dark:border-0">
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/transport2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.VAN_energy_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span>
                        {/* 12012.80{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span> */}
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89]">
                        {t('Kwh')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.VAN_energy_saving_pct_change > 0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.VAN_energy_saving_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/car3.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.SEDAN_energy_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span>
                        {/* 12012.80{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span> */}
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        {t('Kwh')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.SEDAN_energy_saving_pct_change > 0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.SEDAN_energy_saving_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/tempo2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.PICKUPTRUCK_energy_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span>
                        {/* 12012.80{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span> */}
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        {t('Kwh')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.PICKUPTRUCK_energy_saving_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.PICKUPTRUCK_energy_saving_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[28px] text-[#27403F] dark:text-white mt-[10px]">
                {stateData?.Energy_saving?.toFixed(2) || 0}
                {/* 500000.54 */}
                {" "}
                <span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="border-[1px] shadow-xl flex rounded-lg p-3 dark:bg-[#363636] dark:border-0">
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/transport2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.VAN_energy_saving_1000miles?.toFixed(2) ||
                          0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        {t('Kwh')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.VAN_energy_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.VAN_energy_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/car3.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.SEDAN_energy_saving_1000miles?.toFixed(2) ||
                          0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        {t('Kwh')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.SEDAN_energy_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.SEDAN_energy_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/tempo2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.PICKUPTRUCK_energy_saving_1000miles?.toFixed(
                          2
                        ) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        {t('Kwh')}
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.PICKUPTRUCK_energy_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowUp />
                          ) : (
                            <BsArrowDown />
                          )}{" "}
                          {stateData?.PICKUPTRUCK_energy_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[28px] text-[#27403F] dark:text-white mt-[10px]">
                {stateData?.Energy_saving_1000miles?.toFixed(2) || 0}{" "}
                <span className="text-[#787F89] text-[12px] font-normal">{t('Kwh')}</span>
              </div>
            </div>
            <div className="w-full col-span-1 flex items-center justify-center">
              {/* <AreaChart type="energy_saving" color="#5DA3E9" /> */}
              <OdometerBox
                currencyType={t("Kwh")}
                amount={stateData?.cumulative_energy_saving?.toFixed(2) || 0}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 min-h-[185px] bg-white p-3 pb-2.5 rounded-lg gap-7 dark:bg-[#444444]">
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="border-[1px] shadow-xl flex rounded-lg p-3 dark:bg-[#363636] dark:border-0">
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/transport2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.VAN_Co2_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kg')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        kg
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.VAN_Co2_saving_pct_change > 0 ? (
                            <BsArrowDown />
                          ) : (
                            <BsArrowUp />
                          )}{" "}
                          {stateData?.VAN_Co2_saving_pct_change?.toFixed(3) ||
                            0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/car3.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.SEDAN_Co2_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kg')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        kg
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.SEDAN_Co2_saving_pct_change > 0 ? (
                            <BsArrowDown />
                          ) : (
                            <BsArrowUp />
                          )}{" "}
                          {stateData?.SEDAN_Co2_saving_pct_change?.toFixed(3) ||
                            0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/tempo2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.PICKUPTRUCK_Co2_saving?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kg')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        kg
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.PICKUPTRUCK_Co2_saving_pct_change > 0 ? (
                            <BsArrowDown />
                          ) : (
                            <BsArrowUp />
                          )}{" "}
                          {stateData?.PICKUPTRUCK_Co2_saving_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[28px] text-[#27403F] dark:text-white mt-[10px]">
                {stateData?.Co2_saving?.toFixed(2) || 0}{" "}
                <span className="text-[#787F89] text-[12px] font-normal">{t('Kg')}</span>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="border-[1px] shadow-xl flex rounded-lg p-3 dark:bg-[#363636] dark:border-0">
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/transport2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.VAN_Co2_saving_1000miles?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kg')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        kg
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.VAN_Co2_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowDown />
                          ) : (
                            <BsArrowUp />
                          )}{" "}
                          {stateData?.VAN_Co2_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/car3.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.SEDAN_Co2_saving_1000miles?.toFixed(2) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kg')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        kg
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.SEDAN_Co2_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowDown />
                          ) : (
                            <BsArrowUp />
                          )}{" "}
                          {stateData?.SEDAN_Co2_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                  <div className="w-1/3 flex flex-col items-center">
                    <div>
                      <img
                        src="/assets/icons/tempo2.svg"
                        alt="car"
                        className="w-8 h-8"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="text-[22px] w-[max-content] font-bold text-center text-[#27403F] dark:text-white">
                        {stateData?.PICKUPTRUCK_Co2_saving_1000miles.toFixed(
                          2
                        ) || 0}{" "}<span className="text-[#787F89] text-[12px] font-normal">{t('Kg')}</span>
                      </div>
                      {/* <div className="text-xs text-center text-[#787F89] text-[12px] font-normal">
                        kg
                      </div> */}
                    </div>
                    <div>
                      <div className="text-xs text-[#737B8B] flex items-center">
                        <span className="inline-flex items-center text-[#0DBF4F] mr-1.5">
                          {stateData?.PICKUPTRUCK_Co2_saving_1000miles_pct_change >
                          0 ? (
                            <BsArrowDown />
                          ) : (
                            <BsArrowUp />
                          )}{" "}
                          {stateData?.PICKUPTRUCK_Co2_saving_1000miles_pct_change?.toFixed(
                            3
                          ) || 0}
                          %
                        </span>{" "}
                        
                      </div>
                      {/* <div className="text-xs text-center text-[#737B8B]">last Instance</div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[28px] text-[#27403F] dark:text-white mt-[10px]">
                {stateData?.Co2_saving_1000miles?.toFixed(2) || 0}{" "}
                <span className="text-[#787F89] text-[12px] font-normal">{t('Kg')}</span>
              </div>
            </div>
            <div className="w-full col-span-1 flex items-center justify-center">
              {/* <AreaChart type="co2_saving" color="#1AE8CE" /> */}
              <OdometerBox
                currencyType={t("Kg")}
                amount={stateData?.cumulative_CO2_saving?.toFixed(2) || 0}
              />
            </div>
          </div>
        </div>
      </div>
      <EfficiencyModal open={open} setOpen={() => setOpen(!open)} language={selectedLanguage} />
    </Layout>
  );
};

export default Econet;
