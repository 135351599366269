import Header from "../component/video-app/Header";
import UploadFileCard from "../component/video-app/UploadFileCard";
import DownloadFileCard from "../component/video-app/DownloadFileCard";
import { createContext, useState } from "react";

export const VideoCompressionContext = createContext();

const VideoCompressionPage = () => {
  const [fileUploaded, setFileUploaded] = useState();
  const [fileCompressed, setFileCompressed] = useState();
  const [fileCompressing, setFileCompressing] = useState();
  const [fileDownloaded, setFileDownloaded] = useState();
  const [compressPayload, setCompressPayload] = useState();
  const [isPlayingAlong, setIsPlayingAlong] = useState(false);
  return (
    <VideoCompressionContext.Provider
      value={{
        fileUploaded,
        setFileUploaded,
        fileCompressed,
        setFileCompressed,
        fileCompressing,
        setFileCompressing,
        compressPayload,
        setCompressPayload,
        fileDownloaded,
        setFileDownloaded,
        isPlayingAlong,
        setIsPlayingAlong,
      }}
    >
      <main className="h-screen flex flex-col content-center gap-5">
        <Header></Header>

        <section className="flex flex-col md:flex-row justify-center items-center gap-5 px-5 pb-5 grow">
          <UploadFileCard />
          <DownloadFileCard />
        </section>
      </main>
    </VideoCompressionContext.Provider>
  );
};

export default VideoCompressionPage;
