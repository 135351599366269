const Header = () => {
  return (
    <header className="flex justify-between items-center px-5 bg-[#E5F8F2] rounded-3xl shadow-xl">
      <img src="/assets/dynamo-logo.png" alt="dynamo logo" className="h-20" />
      <h1 className="md:text-2xl text-green2">
        Self Optimization Function For Intelligent Automation
      </h1>
    </header>
  );
};

export default Header;
