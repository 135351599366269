import React from 'react'
import Layout from '../component/common/Layout/Layout'
import Header from '../component/common/Header/Header'
import Icon from '@mdi/react'
import { mdiChevronLeft } from '@mdi/js'

const Notification = () => {
  return (
    <Layout>
      <div className='h-full'>
        <Header />
        <div>
          <div className='flex items-center mb-6 mt-[32px]'>
            <div className='text-2xl mr-[18px]'>Dashboard</div>
            <Icon path={mdiChevronLeft} className='text-primary mr-2 rotate-180' size={1} />
            <div className='font-bold text-2xl'>Need attention now</div>
          </div>
          <div className='rounded-lg bg-white p-[24px]'>
            <div className='flex gap-[12px] items-center'>
              <h2 className='text-[#01221A] text-[24px] font-bold'>Notifications</h2>
              <div className='bg-[#F2383A] w-[24px] h-[24px] text-white flex justify-center items-center rounded-full text-[14px]'>3</div>
            </div>
            <div className='flex gap-[16px] mt-[16px]'>
              <div className='bg-[#737B8B] w-[1px] mt-[12px] mb-[10px]' />
              <div className='max-w-[800px] w-full'>
               <div>
                <p className='text-[#737B8B] font-medium relative after:content:[""] after:absolute after:rounded-full after:left-19px] after:top-[8px] after:bg-[#737B8B] after:w-[6px] after:h-[6px] after:left-[-19px]'>Today</p>
                  <div className='mt-2'>
                    <div className='shadow-[0px_2px_8px_rgba(0,0,0,0.11)] flex justify-between items-center rounded-lg p-[16px]'>
                      <div className='flex items-center gap-2'>
                        <div className='relative'>
                        <img src="/assets/icons/warning-black.svg" alt="warning-black" className='relative z-[1]' />
                        <div className='bg-[#FFB0B0] w-[16px] h-[16px] rounded-full absolute left-0 top-[50%] translate-y-[-50%]'></div>
                        </div>
                        <p className='text-[16px] text-black'>10 active vehicles with low battery</p>
                      </div>
                      <p className='text-[#417DFC] text-[16px] font-medium tracking-[.5px]'>Contact drivers </p>
                    </div>
                    <div className='shadow-[0px_2px_8px_rgba(0,0,0,0.11)] flex justify-between items-center rounded-lg p-[16px] mt-[16px]'>
                      <div className='flex items-center gap-2'>
                        <div className='relative'>
                        <img src="/assets/icons/warning-black.svg" alt="warning-black" className='relative z-[1]' />
                        <div className='bg-[#FFB0B0] w-[16px] h-[16px] rounded-full absolute left-0 top-[50%] translate-y-[-50%]'></div>
                        </div>
                        <p className='text-[16px] text-black'>1752 Vehicles are running on low tire pressure</p>
                      </div>
                      <p className='text-[#417DFC] text-[16px] font-medium tracking-[.5px]'>Send to the garage</p>
                    </div>
                    <div className='shadow-[0px_2px_8px_rgba(0,0,0,0.11)] flex justify-between items-center rounded-lg p-[16px] mt-[16px]'>
                      <div className='flex items-center gap-2'>
                        <div className='relative'>
                        <img src="/assets/icons/Warning-service.svg" alt="warning-black" className='relative z-[1]' />
                        <div className='bg-[#FFB0B0] w-[16px] h-[16px] rounded-full absolute left-0 top-[50%] translate-y-[-50%]'></div>
                        </div>
                        <p className='text-[16px] text-black'>8 vehicles need general service</p>
                      </div>
                      <p className='text-[#417DFC] text-[16px] font-medium tracking-[.5px]'>Book</p>
                    </div>
                  </div>
               </div>
               <div className='mt-[48px]'>
                <p className='text-[#737B8B] font-medium relative after:content:[""] after:absolute after:rounded-full after:left-19px] after:top-[8px] after:bg-[#737B8B] after:w-[6px] after:h-[6px] after:left-[-19px]'>Wednesday</p>
                  <div className='mt-2'>
                    <div className='shadow-[0px_2px_8px_rgba(0,0,0,0.11)] flex justify-between items-center rounded-lg p-[16px]'>
                      <div className='flex items-center gap-2'>
                        <div className='relative'>
                        <img src="/assets/icons/warning-black.svg" alt="warning-black" className='relative z-[1]' />
                        <div className='bg-[#CDCDCD] w-[16px] h-[16px] rounded-full absolute left-0 top-[50%] translate-y-[-50%]'></div>
                        </div>
                        <p className='text-[16px] text-black'>10 active vehicles with low battery</p>
                      </div>
                      <p className='text-[#CDCDCD] text-[16px] font-medium tracking-[.5px]'>Contact drivers </p>
                    </div>
                    <div className='shadow-[0px_2px_8px_rgba(0,0,0,0.11)] flex justify-between items-center rounded-lg p-[16px] mt-[16px]'>
                      <div className='flex items-center gap-2'>
                        <div className='relative'>
                        <img src="/assets/icons/warning-black.svg" alt="warning-black" className='relative z-[1]' />
                        <div className='bg-[#CDCDCD] w-[16px] h-[16px] rounded-full absolute left-0 top-[50%] translate-y-[-50%]'></div>
                        </div>
                        <p className='text-[16px] text-black'>1752 Vehicles are running on low tire pressure</p>
                      </div>
                      <p className='text-[#CDCDCD] text-[16px] font-medium tracking-[.5px]'>Send to the garage</p>
                    </div>
                    <div className='shadow-[0px_2px_8px_rgba(0,0,0,0.11)] flex justify-between items-center rounded-lg p-[16px] mt-[16px]'>
                      <div className='flex items-center gap-2'>
                        <div className='relative'>
                        <img src="/assets/icons/setting-black.svg" alt="warning-black" className='relative z-[1]' />
                        <div className='bg-[#CDCDCD] w-[16px] h-[16px] rounded-full absolute left-0 top-[50%] translate-y-[-50%]'></div>
                        </div>
                        <p className='text-[16px] text-black'>8 vehicles need general service</p>
                      </div>
                      <p className='text-[#CDCDCD] text-[16px] font-medium tracking-[.5px]'>Book</p>
                    </div>
                  </div>
               </div>
               <p className='text-[#417DFC] font-medium relative after:content:[""] after:absolute after:rounded-full after:left-19px] after:top-[8px] after:bg-[#737B8B] after:w-[6px] after:h-[6px] after:left-[-19px] mt-[40px] tracking-[.5px] flex item-center gap-1'>See previous messages <img src='assets/icons/ChevronDown.svg' alt='ChevronDown' /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Notification