import React, { useEffect, useState } from "react";
import Layout from "../component/common/Layout/Layout";
import Header from "../component/common/Header/Header";
import { Progressbar } from "./Progressbar";
import { useDispatch } from "react-redux";
import { UseContextData } from "../context/dateContext";
import { getBatteryNetData } from "../redux/action/action";
import { Wrapper } from "@googlemaps/react-wrapper";
import BatterynetMap from "./BatterynetMap";
import BatterynetChargeChart from "../component/baterynet/BatterynetChargeChart";
import BatterynetTempratureChart from "../component/baterynet/BatterynetTempratureChart";
import { useTranslation } from "react-i18next";

const BatterynetNew = () => {
  const mapKey = process.env.REACT_APP_VITE_MAP_KEY;

  const { selectedVin, isDarkTheme } = UseContextData();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [showTempPopup, setShowTempPopup] = useState(false);

  const togglePopup = (value) => {
    setShowPopup(value);
  };

  const toggleTempPopup = (value) => {
    setShowTempPopup(value);
  };

  let vinDataIntervalId = null;

  useEffect(() => {
    if (selectedVin) {
      getBatterynet();
      vinDataIntervalId = setInterval(() => {
        getBatterynet();
      }, 30000);
    }

    return () => {
      if (selectedVin) {
        if (vinDataIntervalId) {
          clearInterval(vinDataIntervalId);
        }
      }
    };
  }, [selectedVin]);

  const [batteryData, setBatteryData] = useState({
    ambientTemperature: 0,
    batteryChargeStart: 0,
    batteryChargeEnd: 0,
    batteryTempStart: 0,
    batteryTempEnd: 0,
    distance: 0,
    previous_duration: 0,
    current_duration: 0,
    cabinTemperature: 0,
    weather: "",
    vehicleId: "",
    startLatLng: {
      lat: 0,
      lng: 0,
    },
    endLatLng: {
      lat: 0,
      lng: 0,
    },
    soh: 0,
    batteryId: "",
    todays_time: 0,
    todays_miles: 0,
  });

  const getBatterynet = async () => {
    const requestParams = { vehicleId: selectedVin };
    try {
      const response = await dispatch(getBatteryNetData(requestParams));
      const responseData = response?.payload?.data;
      setBatteryData({
        ...batteryData,
        ambientTemperature:
          +responseData?.ambient_temperature_start?.toFixed(1) || 0,
        batteryChargeStart:
          +responseData?.battery_charge_start?.toFixed(2) * 100 || 0,
        batteryChargeEnd: +responseData?.battery_charge_end?.toFixed(2) || 0,
        batteryTempStart:
          +responseData?.battery_temperature_start?.toFixed(1) || 0,
        batteryTempEnd: +responseData?.battery_temperature_end?.toFixed(1) || 0,
        distance: +responseData?.distance?.toFixed(1) || 0,
        previous_duration: +responseData?.previous_duration?.toFixed(2) || 0,
        current_duration: +responseData?.current_duration?.toFixed(2) || 0,
        cabinTemperature:
          +responseData?.target_cabin_temperature?.toFixed(1) || 0,
        weather: responseData?.weather || "",
        vehicleId: responseData?.vehicle_id || "",
        startLatLng: {
          lat: responseData?.lat_start,
          lng: responseData?.long_start,
        },
        endLatLng: {
          lat: responseData?.latitude,
          lng: responseData?.longitude,
        },
        soh: responseData?.SoH?.toFixed(2) || "",
        batteryId: responseData?.battery_id || "",
        todays_time: responseData?.Todays_time?.toFixed(2) || 0,
        todays_miles: responseData?.Todays_miles?.toFixed(2) || 0,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const roundValuePre = Math.round(batteryData?.previous_duration);
  const roundValueCur = Math.round(batteryData?.current_duration);
  const todays_time = Math.round(batteryData?.todays_time);
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <div className="h-full">
        <Header selectedMenu={(selectedOption) => selectedOption} />
      </div>
      <div className="mt-[12px]">
        <h2 className="text-[#27403F] text-[24px] dark:text-white">
          {t("Real Time Battery Status")}
        </h2>
      </div>
      <div>
        <div className="flex gap-[11px]">
          <div className="shadow-[0_0_12px_rgba(0,0,0,0.05)] bg-white dark:bg-box-pattern2 p-[30px_20px_25px_20px] max-w-[400px] rounded-[8px] min-h-[303px] w-full relative">
            <h1 className="text-[#2C2E43] text-[14px] font-normal mt-[12px] mb-[24px] leading-[28px] text-center dark:text-white">
              {t("State of Health")} {t("(SoH)")}{" "}
            </h1>
            {batteryData?.soh <= 30 ? (
              <div className="battery-pro">
                <Progressbar
                  value={batteryData?.soh}
                  className="w-[120px] mx-auto h-auto"
                />
              </div>
            ) : (
              <div className="flex flex-col justify-between items-center">
                <Progressbar
                  value={batteryData?.soh}
                  className="w-[120px] mx-auto h-auto"
                />
              </div>
            )}
            {batteryData?.soh <= 30 ? (
              <>
                <div className="p-2 border-[2px] border-[#fb5d04] relative mt-[60px] w-full">
                  <div className="flex justify-between gap-1 items-center">
                    <div className="flex flex-col gap-1">
                      <p className="text-[#fb5d04] text-[17px] font-extrabold leading-5">
                        {t("Replace Your Battery")}
                      </p>
                      <p className="text-[#fb5d04] text-[14px] leading-4">
                        {t("The state of health of your battery")} <br />{" "}
                        {t("is under 30%")}
                      </p>
                    </div>
                    <div>
                      <button className="max-w-[130px] p-[4px] bg-[#5DA3E9] text-white text-[14px] font-bold rounded-md flex items-center justify-center w-full relative">
                        {t("Notify garages")}{" "}
                      </button>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/assets/icons/batteryvoltage.svg"
                      alt="batteryhelth"
                      className="absolute -top-[40px] left-[45%] w-[50px]"
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div className=" bg-[#FFFFFF] dark:bg-box-pattern2 flex-1 min-h-[300px] rounded-[8px] p-2.5 max-w-[700px]">
            <div>
              <div className="h-[200px] my-[5px] px-[3px]">
                <Wrapper apiKey={mapKey} libraries={["places"]}>
                  <BatterynetMap batteryData={batteryData} />
                </Wrapper>
              </div>
            </div>
            <div className="flex justify-between items-center gap-1 mt-4 mb-2">
              <div className="flex flex-col gap-1">
                <div className="flex gap-1 items-center">
                  <div className="relative w-[40px] h-[40px] flex items-center justify-center">
                    <img
                      src="/assets/icons/temperature-2.svg"
                      className="w-[24px]"
                      alt="batterytemp"
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[12px] text-[#292B23] font-bold dark:text-white">
                      {/* {t("Initial Battery")} <br /> {t("Temperature")} */}
                      {i18n.language === "pt" ? (
                        <>
                          {"Bateria,"} <br /> {"temperatura final"}
                        </>
                      ) : i18n.language === "es" ? (
                        <>
                          {"Temperatura Inicial"} <br /> {"de la Batería"}
                        </>
                      ) : (
                        <>
                          {t("End Battery")} <br /> {t("Temperature")}
                        </>
                      )}
                    </p>
                    <p className="text-[20px] font-bold text-[#70B96E]">
                      {batteryData?.batteryTempStart} °C
                    </p>
                  </div>
                </div>
                <div className="flex gap-1 items-center">
                  <div>
                    <img
                      src="/assets/icons/chargeicon2.svg"
                      alt="chargeicon"
                      className="w-[40px]"
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[12px] text-[#292B23] font-bold dark:text-white">
                      {t("Initial Charge")}
                    </p>
                    <p className="text-[20px] font-bold text-[#70B96E]">
                      {batteryData?.batteryChargeStart} %
                    </p>
                  </div>
                </div>
              </div>
              <div className="max-w-[170px] w-full">
                <div className="bg-[#EFF0F5] rounded-[2px] py-5 px-2 text-center relative dark:bg-[#222222]">
                  <div className="absolute -top-[15px] left-1/2 -translate-x-1/2 ">
                    <img
                      src="/assets/icons/loction2.svg"
                      alt="loction"
                      className="w-[30px]"
                    />
                  </div>
                  <div className="pt-2">
                    <p className="text-[14px] font-bold text-[#01221A] dark:text-white">
                      {t("Current Trip Detail")}
                    </p>
                  </div>
                  <div className="flex gap-2 justify-center">
                    <div className="pt-2">
                      <p className="text-[13px] text-[#01221A] font-bold dark:text-white">
                        {batteryData?.current_duration}
                        <span className="text-[12px] font-semibold text-[#787F89] dark:text-white">
                          {t("miles")}
                        </span>
                      </p>
                    </div>
                    <div className="pt-2">
                      <p className="text-[13px] text-[#01221A] font-bold dark:text-white">
                        0h : {roundValuePre}
                        <span className="text-[12px] font-semibold text-[#787F89] dark:text-white">
                          {t("min")}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="felx flex-col gap-1">
                <div className="flex gap-1 items-center">
                  <div className="flex flex-col">
                    <p className="text-[12px] text-[#292B23] font-bold dark:text-white">
                      {/* {t("End Battery")}
                      <br /> {t("Temperature")} */}
                      {i18n.language === "pt" ? (
                        <>
                          {"Bateria,"} <br /> {"temperatura final"}
                        </>
                      ) : i18n.language === "es" ? (
                        <>
                          {"Temperatura Final"} <br /> {"de la Batería"}
                        </>
                      ) : (
                        <>
                          {t("End Battery")} <br /> {t("Temperature")}
                        </>
                      )}
                    </p>
                    <p className="text-[20px] font-bold text-[#E6323D]">
                      {batteryData?.batteryTempEnd} °C
                    </p>
                  </div>
                  <div className="relative w-[40px] h-[40px] flex items-center justify-center">
                    <img
                      src="/assets/icons/temperature-2.svg"
                      className="w-[24px]"
                      alt="batterytemp"
                    />
                  </div>
                </div>
                <div className="flex gap-1 items-center justify-between">
                  <div className="flex flex-col">
                    <p className="text-[12px] text-[#292B23] font-bold dark:text-white">
                      {t("End Charge")}
                    </p>
                    <p className="text-[20px] font-bold text-[#70B96E]">
                      {batteryData?.batteryChargeEnd * 100} %
                    </p>
                  </div>
                  <div>
                    <img
                      src="/assets/icons/chargeicon2.svg"
                      className="w-[40px]"
                      alt="chargeicon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" bg-[#FFFFFF] dark:bg-box-pattern2 flex-1 min-h-[300px] rounded-[8px] px-5 pb-2 pt-4 shadow-[0px_1px_2px_1px_#EFF0F5] dark:shadow-none">
            <div className="w-full grid grid-cols-2 gap-3">
              <div className="">
                <div className="bg-[#EFF0F5] dark:bg-[#222222] rounded-[2px] p-2 text-center relative">
                  <div className="absolute -top-[15px] left-1/2 -translate-x-1/2 ">
                    <img
                      src="/assets/icons/hilighticon2.svg"
                      className="w-[30px]"
                      alt="hilighticon"
                    />
                  </div>
                  <div className="pt-2">
                    <p className="text-[18px] font-bold text-[#01221A] dark:text-white">
                      {t("Today's Highlights")}
                    </p>
                    <p className="text-[12px] font-bold text-[#787F89] dark:text-white">
                      (04-03-2024)
                    </p>
                  </div>
                  <div className="flex gap-2 justify-center pt-6">
                    <div className="flex  gap-1">
                      <p style={{textWrap:'nowrap'}} className="text-[14px] text-nowrap text-[#01221A] font-bold dark:text-white">
                        {batteryData?.todays_miles}{" "}
                        <span className="text-[12px] font-semibold text-[#787F89] dark:text-white">
                          {t("Miles")}
                        </span>
                      </p>
                    </div>
                    <div className="flex  gap-1">
                      <p style={{textWrap:'nowrap'}} className="text-[14px]  text-[#01221A] font-bold dark:text-white">
                        0
                        <span className="text-[12px] font-semibold text-[#787F89] dark:text-white">
                          {t("Hours")}{" "}
                        </span>
                        {todays_time}
                        <span className="text-[12px] font-semibold text-[#787F89] dark:text-white">
                          {t("Min")}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="bg-[#EFF0F5] dark:bg-[#222222] rounded-[2px] p-2 text-center relative h-full">
                  <div className="absolute -top-[15px] left-1/2 -translate-x-1/2 ">
                    <img
                      src="/assets/icons/hilighticon2.svg"
                      className="w-[30px]"
                      alt="hilighticon"
                    />
                  </div>
                  <div className="pt-2">
                    <p
                      className={`   ${
                        i18n.language !== "en" ? "text-[16px]" : "text-[18px]"
                      } font-bold text-[#01221A] dark:text-white`}
                    >
                      {t("Total Distance")} {t("Traveled")}
                    </p>
                  </div>
                  <div className="mt-9">
                    <p className="text-[18px] text-[#01221A] font-bold dark:text-white">
                      {batteryData?.distance}
                      <span className="text-[14px] font-semibold text-[#787F89] dark:text-white">
                        {t("miles")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-3 pt-4 pb-2 ">
              <div>
                <div className="bg-[#EFF0F5] dark:bg-[#222222] rounded-[2px] p-2 text-center relative h-full">
                  <div className="absolute -top-[15px] left-1/2  -translate-x-1/2 ">
                    <img
                      src="/assets/icons/loction2.svg"
                      className="w-[30px]"
                      alt="loction2"
                    />
                  </div>
                  <div className="pt-2">
                    <p className="text-[18px] font-bold text-[#01221A] dark:text-white">
                      {t("Jobs Done")}
                    </p>
                  </div>
                  <div className="flex gap-1 justify-around ">
                    <div className="flex flex-col py-2">
                      <p className="text-[18px] text-[#01221A] font-bold dark:text-white">
                        13{" "}
                        <span className="text-[14px] font-semibold text-[#787F89] dark:text-white">
                          {t("Jobs")}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="bg-[#EFF0F5] dark:bg-[#222222] rounded-[2px] p-2 text-center relative">
                  <div className="absolute -top-[15px] left-1/2 -translate-x-1/2 ">
                    <img
                      src="/assets/icons/clock2.svg"
                      className="w-[30px]"
                      alt="clock"
                    />
                  </div>
                  <div className="pt-2">
                    <p
                      className={`${
                        i18n.language !== "en" ? "text-[16px]" : "text-[18px]"
                      }  font-bold text-[#01221A] dark:text-white`}
                    >
                      {t("Total Driving Time")}
                    </p>
                  </div>
                  <div className="py-2">
                    <p className="text-[18px] text-[#01221A] font-bold dark:text-white">
                      0
                      <span className="text-[14px] font-semibold text-[#787F89] dark:text-white">
                        {t("Hours")}{" "}
                      </span>
                      {" "}{roundValueCur}
                      <span className="text-[14px] font-semibold text-[#787F89] dark:text-white">
                        {t("Min")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-3 pt-4 pb-2 ">
              <div>
                <div className="bg-[#EFF0F5] dark:bg-[#222222] rounded-[2px] p-2 text-center relative h-full">
                  <div className="absolute -top-[15px] left-1/2 -translate-x-1/2 ">
                    <img
                      src="/assets/icons/id3.svg"
                      className="w-[30px]"
                      alt="weather"
                    />
                  </div>
                  <div className="pt-2">
                    <p className="text-[18px] font-bold text-[#01221A] dark:text-white">
                      {t("Ambient Temperature")}
                    </p>
                  </div>
                  <div className="flex gap-1 justify-around ">
                    <div className="flex flex-col py-2">
                      <p className="text-[18px] text-[#01221A] font-bold dark:text-white">
                        {batteryData?.ambientTemperature}º{" "}
                        <span className="text-[14px] font-semibold text-[#787F89] dark:text-white">
                          C
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="bg-[#EFF0F5] dark:bg-[#222222]  rounded-[2px] p-2 text-center relative h-full">
                  <div className="absolute -top-[15px] left-1/2 -translate-x-1/2 ">
                    <img
                      src="/assets/icons/id4.svg"
                      className="w-[30px]"
                      alt="humiditytemp"
                    />
                  </div>
                  <div className="pt-2">
                    <p className="text-[18px] font-bold text-[#01221A] dark:text-white">
                      {t("Humidity")}
                    </p>
                  </div>
                  <div className="py-2">
                    <p className="text-[18px] text-[#01221A] font-bold dark:text-white">
                      {batteryData?.cabinTemperature}{" "}
                      <span className="text-[14px] font-semibold text-[#787F89] dark:text-white">
                        °C
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex mt-[14px] gap-[17px]">
        <div className="flex flex-1 gap-[7px] bg-blue-gray-50 dark:bg-box-pattern2 p-[10px] rounded-[8px] border-3 border-black ">
          <div className="flex-1 bg-white rounded-[8px] dark:bg-[#222222] relative">
            <div className="p-[15px] pb-0 flex items-center gap-2 justify-between  min-h-[107px]">
              <p className="text-[#27403F] text-xl dark:text-white">
                {t("State of")}
                <br /> {t("Charge")}
              </p>
              {showPopup && (
                <div
                  className={`p-2 border-[2px] border-[#fb5d04] absolute right-2 top-2 z-20 mt-[25px] ${
                    i18n.language === "pt" || i18n.language === "es"
                      ? "max-w-[450px]"
                      : i18n.language === "zh"
                      ? "w-[380px]"
                      : "w-[380px]"
                  }  `}
                >
                  <div className="flex justify-between gap-1   mt-3">
                    <div className="flex flex-col gap-1">
                      <p className="text-[#fb5d04]  text-[17px] font-extrabold leading-[10px]">
                        {t("Recharge Your Battery")}
                      </p>
                      <p className="text-[#fb5d04] text-[14px] leading-4">
                        {t("The battery is under 40%")}
                      </p>
                    </div>
                    <div>
                      <button
                        className={`p-[4px] bg-[#5DA3E9] ${
                          i18n.language !== "en"
                            ? "max-w-[180px]"
                            : "max-w-[130px]"
                        } text-white text-[14px] font-bold rounded-md flex items-center justify-center w-full relative leading-[17px]`}
                      >
                        {t("Route to Nearest Charging Station")}{" "}
                      </button>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/assets/icons/batteryvoltage.svg"
                      className="absolute -top-8 right-[10%] w-[50px]"
                      alt="batteryvoltage"
                    />
                  </div>
                </div>
              )}
            </div>
            <BatterynetChargeChart
              togglePopup={togglePopup}
              isDarkTheme={isDarkTheme}
            />
          </div>

          <div className="flex-1 bg-white rounded-[8px] dark:bg-[#222222] relative">
            <div className="p-[15px] pb-0 flex items-center gap-2 justify-between  min-h-[107px]">
              <p className="text-[#27403F] text-xl dark:text-white">
                {/* {t("Battery")} <br /> {t("Temperature")} */}
                {i18n.language === "es" ? (
                  <>
                    {"Temperatura de la"} <br /> {"Batería"}
                  </>
                ) : (
                  <>
                    {t("Battery")} <br /> {t("Temperature")}
                  </>
                )}
              </p>
              {showTempPopup && (
                <div className="p-2 border-[2px] border-[#fb5d04] absolute right-2 top-2 z-20 mt-[25px] w-full max-w-[380px]">
                  <div className="flex justify-between gap-1  mt-3">
                    <div className="flex flex-col gap-1">
                      <p className="text-[#fb5d04] text-[17px] font-extrabold leading-[20px]">
                        {t("Battery Overheating!")}
                      </p>
                      <p className="text-[#fb5d04] text-[14px] leading-4">
                        {t("Stop Immediately")}
                      </p>
                    </div>
                    <div>
                      <button className="max-w-[130px] p-[4px] bg-[#5DA3E9] text-white text-[14px] font-bold rounded-md flex items-center justify-center w-full relative leading-[17px]">
                        {t("Call a Mechanic")}
                      </button>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/assets/icons/batteryvoltage.svg"
                      className="absolute -top-8 right-[10%] w-[50px]"
                      alt="batteryvoltage2"
                    />
                  </div>
                </div>
              )}
            </div>
            <BatterynetTempratureChart
              toggleTempPopup={toggleTempPopup}
              isDarkTheme={isDarkTheme}
            />
          </div>
        </div>

        <div>
          <div className="shadow-xl bg-white p-[10px] rounded-[8px] min-w-[215px] relative mb-[8.2px] dark:bg-[#222222]">
            <div className="flex items-center gap-[10px]">
              <div className="w-[34px] h-[34px] leading-[34px] rounded-full flex justify-center items-center">
                <img
                  src="/assets/icons/transport2.svg"
                  className=""
                  alt="vanicon"
                />
              </div>
              <p className="text-[#2C2E43] font-light text-[20px] dark:text-white">
                {t("Vehicle Id")}
              </p>
            </div>
            <h1 className="text-[#2C2E43] text-[20px] font-bold my-[30px] leading-[28px] mx-auto text-center dark:text-white">
              {batteryData?.vehicleId}
            </h1>
          </div>

          <div className="shadow-xl bg-white p-[10px] min-w-[155.28px] rounded-[8px] w-full relative mb-[8.2px] dark:bg-[#222222]">
            <div className="flex items-center gap-[10px]">
              <div>
                <img
                  src="/assets/icons/batteryicon2.svg"
                  className="w-[34px] h-[34px]"
                  alt="batteryicon"
                />
              </div>
              <p className="text-[#2C2E43] font-light text-[20px] dark:text-white">
                {t("Battery Id")}
              </p>
            </div>
            <h1 className="text-[#2C2E43] text-[25px] font-bold my-[25px] leading-[28px] text-center dark:text-white">
              {batteryData?.batteryId}
            </h1>
          </div>

          <div className="shadow-xl bg-white p-[11px_0px_13px_10px]  min-w-[155.28px] rounded-[8px] w-full dark:bg-[#222222]">
            <div className="flex items-center gap-[5px]">
              <img
                className="w-[34px] h-[34px]"
                src="/assets/icons/map-trip2.svg"
                alt="map-trip"
              />
              <p
                className={`text-[#2C2E43] font-light ${
                  i18n.language !== "en" ? "text-[18px]" : "text-[20px]"
                }  dark:text-white`}
              >
                {t("Trip Number")}
              </p>
            </div>
            <div className="flex items-center gap-[23px] mt-[20px]">
              <div className="flex flex-col items-center">
                <p className="text-[#787F89] text-[17px] dark:text-white">7</p>
                <p className="text-[#787F89] text-[17px] dark:text-white">
                  {t("Jobs done")}
                </p>
              </div>
              <h1 className="text-[#2C2E43] text-[48px] font-bold dark:text-white">
                8
              </h1>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BatterynetNew;
