import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = ({ stateData }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'pie',
      },
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.series[opts.seriesIndex] + "%";
        },
        style: {
          fontSize: "11px",
          // fontWeight: "bold",
          colors: ['#000'],
        },
      },
      // labels: ['PickUp & Trucks', 'SEDAN', 'Vans'],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -13,
          },
        },
      },
      legend: {
        show: false,
      },
      fill: {
        colors: ["#0DBF4F", "#F2383A", "#F2C049"],
      },
      colors: ["#000000"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      }],
    },
  });

  useEffect(() => {
    if (stateData) {
      const { Green_percent, Red_percent, Yellow_percent } = stateData;
      setChartData({
        series: [Green_percent, Red_percent, Yellow_percent],
        options: chartData.options,
      });
    }
  }, [stateData]);

  return (
    <div id="chart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="pie" width={165} />
    </div>
  );
}

export default PieChart;
