import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../redux/action/action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [data, setData] = useState({
    email: "",
    new_password: "",
    repeat_password: "",
  });
  let params = useParams();
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });

    if (name === "email") {
      setErrors((prevState) => ({
        ...prevState,
        email: !/\S+@\S+\.\S+/.test(value) ? "Invalid email address" : "",
      }));
    } else if (name === "new_password") {
      setErrors((prevState) => ({
        ...prevState,
        new_password:
          value.length < 6 ? "Password must be at least 6 characters" : "",
      }));
    } else if (name === "repeat_password") {
      setErrors((prevState) => ({
        ...prevState,
        repeat_password:
          value !== data.new_password ? "Passwords do not match" : "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestParams = { data: data, token: params?.token };
    if (Object.values(errors).some((error) => error !== "")) {
      setErrorMessage("Please fix the validation errors");
      return;
    }
    await dispatch(resetPassword(requestParams)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.data?.message);
        navigate("/login");
      } else {
        setErrorMessage(res?.payload?.data?.message || "An error occurred");
      }
      setData({
        email: "",
        new_password: "",
        repeat_password: "",
      });
    });
  };

  return (
    <div>
      <div className="h-screen from-bg   grid place-content-center">
        <div className="w-[800px] h-[800px] rounded-full bg-[#F7F9F9] flex  p-5 flex-col text-center">
          <div className="mx-auto mt-[45px]">
            <img alt="" src="/assets/icons/logo.svg" />
          </div>
          <h2 className="text-[#00AEB6] text-[36px] font-bold mt-[31px]">
            Reset password
          </h2>
          <h3 className="text-[#93B9C4] text-[16px]">
            Create your new account
          </h3>
          {errorMessage && <p className="text-[red] text-lg">{errorMessage}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mx-auto mt-[23px]">
              <input
                className="w-[465px] h-[60px] rounded-md mt-[24px] pl-[27px] placeholder:text-[#6FA2B0] text-[#6FA2B0]  focus:outline-none hover:outline-none font-[600]"
                type="email"
                name="email"
                value={data.email}
                placeholder="Email"
                onChange={handleChange}
                required
              />
              <br />
              {errors.email && <p className="text-[red]">{errors.email}</p>}
              <input
                className="w-[465px] h-[60px] rounded-md mt-[24px] pl-[27px] placeholder:text-[#6FA2B0] text-[#6FA2B0]  focus:outline-none hover:outline-none font-[600]"
                type="password"
                name="new_password"
                value={data.new_password}
                placeholder="New Password"
                onChange={handleChange}
                required
              />
              <br />
              {errors.new_password && (
                <p className="text-[red]">{errors.new_password}</p>
              )}

              <input
                className="w-[465px] h-[60px] rounded-md mt-[24px] pl-[27px] placeholder:text-[#6FA2B0] text-[#6FA2B0]  focus:outline-none hover:outline-none font-[600]"
                type="password"
                name="repeat_password"
                value={data.repeat_password}
                placeholder="Repeat Password"
                onChange={handleChange}
                required
              />
            </div>
            {errors.repeat_password && (
              <p className="text-[red]">{errors.repeat_password}</p>
            )}

            <div className="flex mx-auto mt-[26px] gap-[38px] justify-center">
              <h4 className="text-[#93B9C4] text-[16px]">Remember me</h4>
              <h4 className="text-[#93B9C4] text-[16px] cursor-pointer">
                <Link to="/forget-password">Forgot Password?</Link>
              </h4>
            </div>

            <button
              className="mt-[43px] bg-[#00AEB6]  rounded-lg mx-auto w-[187px] h-[50px] text-white text-lg font-bold"
              type="submit"
            >
              Send
            </button>
          </form>

          <h2 className="mt-[23px] text-[16px] text-[#00AEB6] font-[500]">
            <span className="font-bold ml-2 cursor-pointer">
              please,check your mail box
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
