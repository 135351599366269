import { t } from "i18next";
import React, { useState } from "react";
import NotifyModal from "./NotifyModal";
import SubscribeForm from "./SubscribeForm";

function VehicleModel(props) {
  const { show, setShow } = props;
  const [openNotify, setOpenNotify] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <div  className="relative">
        <div
          className={`absolute ${
            show ? "block" : "hidden"
          } top-[-100px] left-[-26px] w-[max-content] pr-2 rounded-md z-50 h-[100px] bg-[#FFF] shadow-xl `}
        >
          <div className="flex items-start gap-2 !p-2 ">
            <div>
              <img
                src="/assets/icons/batteryvoltage2.svg"
                alt="batteryvoltage2.svg"
                className="w-6 h-6"
              />
            </div>
            <div className="">
              <p className="text-[#F37E44] text-[16px] mb-3 leading-[18px] dark:text-black">
                {t("The vehicle is running on")} <br />
                <span className="text-[#F37E44] font-bold dark:text-black">
                  {t("low battery voltage")}
                </span>
              </p>

              <button
                onClick={(e) => {
                  setOpenNotify(true,e.stopPropagation());
                  setShow(false);
                }}
                className="max-w-[max-content] mx-auto px-2 py-2 bg-[#5DA3E9] text-white text-[14px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px] twrap"
              >
                {t("Notify garages")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <NotifyModal
        openNotify={openNotify}
        setOpenNotify={setOpenNotify}
        setOpenForm={setOpenForm}
      />
      <SubscribeForm openForm={openForm} setOpenForm={setOpenForm} />
    </>
  );
}

export default VehicleModel;
