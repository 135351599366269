import Card from "./Card";
import Button from "./Button";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { VideoCompressionContext } from "../../pages/VideoCompression";
import ReactPlayer from "react-player";
import { apiURL, mediaURL } from "./util/baseURL";

const DownloadFileCard = () => {
  const {
    fileCompressed,
    fileDownloaded,
    setFileCompressed,
    fileCompressing,
    setFileDownloaded,
    setFileUploaded,
    compressPayload,
    isPlayingAlong,
    setIsPlayingAlong,
  } = useContext(VideoCompressionContext);

  const [videoSize, setVideoSize] = useState("--");
  const [videoDuration, setVideoDuration] = useState("--");
  const [videoFormat, setVideoFormat] = useState("--");
  const [videoResolution, setVideoResolution] = useState("--");
  const [pixelResolution, setPixelResolution] = useState("--");
  const [timeTaken, setTimeTaken] = useState("--");

  useEffect(() => {
    if (compressPayload) {
      setVideoSize(compressPayload?.format.size);
      setVideoDuration(compressPayload?.format.duration);
      setTimeTaken(compressPayload?.processing_time);
      setVideoFormat(compressPayload?.streams[0].codec_name);
      setVideoResolution(compressPayload?.video_quality);
      setPixelResolution(
        `${compressPayload?.streams[0].height}x${compressPayload?.streams[0].width}`
      );
    }
  }, [compressPayload]);

  const handleDownload = async () => {
    try {
      const response = await axios.get(`${apiURL}/download`, {
        responseType: "blob", // Para obtener un blob (archivo)
      });

      // Crear un objeto URL para el blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crear un enlace temporal y hacer clic en él
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "video_optimized.mp4"); // Nombre del archivo
      document.body.appendChild(link);
      link.click(); // Simula el clic en el enlace para iniciar la descarga
      link.remove(); // Remueve el enlace del DOM
      window.URL.revokeObjectURL(url); // Libera el objeto URL creado

      // alert("File downloaded successfully");
      setFileUploaded(false);
      setFileCompressed(false);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <Card className="flex-1 flex flex-col gap-5  w-full h-full">
      {fileCompressed ? (
        <section className="bg-[#F2F2F2] h-64 md:h-80 justify-center items-center flex flex-col gap-5  shadow-inner grow">
          <ReactPlayer
            url={`${mediaURL}/video_compressed.mp4`}
            controls
            width="100%"
            height="100%"
            playing={isPlayingAlong}
          />
        </section>
      ) : (
        <section className="bg-[#F2F2F2] h-64 md:h-80  justify-center items-center flex flex-col gap-5 shadow-inner grow p-5">
          {fileCompressing ? (
            <>
              <img
                src="/assets/animations/loading1.svg"
                alt="Loading..."
                width={60}
              />
              <p className="text-black font-semibold text-xl">Processing...</p>
            </>
          ) : (
            <p className="text-black font-semibold text-xl">Video Preview</p>
          )}
        </section>
      )}

      <Card className="flex flex-col md:h-2/5 text-green2 gap-5 justify-start">
        <h1 className="text-xl">Processed Details</h1>
        <div className="flex justify-between">
          <div className="flex flex-col gap-3 text-base ">
            <p>New File Size: </p>
            <p>Duration: </p>
            <p>Resolution: </p>
          </div>

          <div className="flex flex-col gap-3 font-semibold text-base text-end">
            <p>
              {Number(videoSize)
                ? Math.floor((100 * Number(videoSize)) / (1024 * 1024)) / 100
                : "--"}{" "}
              MB{" "}
            </p>
            <p>
              {Number(videoSize) ? Math.floor(Number(videoDuration)) : "--"} Sec
            </p>
            <p>
              {videoResolution}{" "}
              <span className="text-xs text-gray1">[{pixelResolution}p]</span>
            </p>
          </div>
        </div>
        {/* {fileCompressing && (
          <ProgressBar labelText={"Processing..."} progress={50} />
        )}
        {fileCompressed && (
          <ProgressBar labelText={"Process completed."} progress={100} />
        )} */}
        {fileCompressed && (
          <div className="flex justify-between">
            <p>Time Taken: </p>
            <p>
              {Number(timeTaken)
                ? Math.floor(100 * Number(timeTaken)) / 100
                : "--"}{" "}
              Sec
            </p>
          </div>
        )}

        <div className="flex flex-col md:flex-row justify-center items-center gap-2 ">
          <Button
            onCLickFunction={() => {
              setIsPlayingAlong(!isPlayingAlong);
            }}
            className="px-8 h-10 w-48"
          >
            Sync Play
          </Button>
          <Button onCLickFunction={handleDownload} className="px-8 h-10 w-48">
            Download
          </Button>
        </div>
      </Card>
    </Card>
  );
};

export default DownloadFileCard;

const ProgressBar = ({ progress = 0, labelText }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <p className="text-base text-green3">{labelText}</p>
        <p className="text-base text-green3"> {`${progress} %`}</p>
      </div>
      <div className="w-full bg-[#D9D9D9] rounded-full h-2.5">
        <div
          className="bg-green3 h-2.5 rounded-full "
          style={{ width: progress + "%" }}
        ></div>
      </div>
    </div>
  );
};
