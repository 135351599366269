import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import { getSeeTrendData } from "../../../redux/action/action";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

highcharts3d(Highcharts);

const BarChart = ({ selectedButton, selectedTimeStamp, isDarkTheme }) => {
  const [chartData, setchartData] = useState();
  const dispatch = useDispatch();
  const fieldNamesParam = selectedButton.join(",");
  const { t } = useTranslation();

  const TextColor = isDarkTheme ? "#ffffff" : "#000000";
  const backgroundColor = isDarkTheme ? "#222222" : "#ffffff";

  useEffect(() => {
    const getchartData = async () => {
      const requestParams = {
        field_name: fieldNamesParam,
        canvas: selectedTimeStamp,
      };
      try {
        const response = await dispatch(getSeeTrendData(requestParams));
        if (response.payload) {
          const responseData = response?.payload;
          setchartData(responseData);
        }
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    };
    getchartData();
  }, [dispatch, fieldNamesParam, selectedTimeStamp]);

  const getSliceCount = () => {
    switch (selectedTimeStamp) {
      case "daily":
        return 15;
      case "weekly":
        return 5;
      case "monthly":
        return 5;
      default:
        return 15;
    }
  };

  const seriesData = selectedButton?.map((button) => ({
    name: t(`multivariateButtons.${button}`),
    data: (chartData?.[button] || [])
      .slice(0, getSliceCount())
      ?.map((value) => (value !== null ? Number(value.toFixed(2)) : null)),
  }));

  const options = {
    chart: {
      //   backgroundColor: {
      //     linearGradient: [0, 0, 500, 500],
      //     stops: [
      //         [0, 'rgb(255, 255, 255)'],
      //         [1, 'rgb(200, 200, 255)']
      //     ]
      // },
      type: "column",
      height: "325px",
      options3d: {
        enabled: true,
        alpha: 20,
        beta: -12,
        depth: 30,
        viewDistance: 120,
      },
      backgroundColor: backgroundColor,
    },
    title: {
      text: "3D Column Chart with Null and 0 Values",
      style: {
        display: "none",
      },
    },
    xAxis: {
      categories: chartData?.Date || [],
      labels: {
        rotation: -30,
        step: 1,
        style: {
          fontSize: 10,
          color: TextColor,
        },
      },
    },
    yAxis: {
      title: {
        text: t("Magnitude"),
        style: {
          color: TextColor,
        },
      },
      labels: {
        style: {
          color: TextColor,
        },
      },
    },
    zAxis: {
      // title: {
      //   text: 'Depth',
      // },
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        depth: 50,
        animation: true,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: TextColor,
      },
    },
    series: seriesData,
  };
  return (
    <div className="!sr-onlyh-[200px]">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarChart;
