import { Wrapper } from '@googlemaps/react-wrapper'
import { Dialog, Transition } from '@headlessui/react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import React, { Fragment } from 'react'
import { IoCloseCircleOutline } from 'react-icons/io5'

const Modal = (props) => {

  const mapKey = process.env.REACT_APP_VITE_MAP_KEY

  const mapOptions = {
    center: { lat: 40.4613484576501, lng: -86.3732816459897 },
    zoom: 9,
  };
  const sourceLocation = { lat: 40.4613484576501, lng: -86.3732816459897 };
  const destinationLocation = { lat: 41.0, lng: -85.0 };
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-[8px] bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:w-[50%] sm:p-[24px_24px_30px_24px] ">
                <div>
                  <div className='flex justify-end items-center '>
                    <div className='flex items-end gap-[15px] text-end'>
                      <IoCloseCircleOutline onClick={() => props.setOpen(false)} className='cursor-pointer text-[30px] ' />
                    </div>
                  </div>
                  <div className='relative'>
                    <Wrapper apiKey={mapKey}>
                      <GoogleMap
                        mapContainerStyle={{
                          minHeight: '500px',
                          height: '100%',
                          position: 'relative',
                          width: '100%',
                          marginBottom: '25px',
                          boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
                          backgroundColor: '#fff',
                          borderRadius: '8px',
                        }}
                        options={mapOptions}
                      >
                        <Marker position={sourceLocation} label="S" />
                        <Marker position={destinationLocation} label="D" />
                      </GoogleMap>
                    </Wrapper>
                    <div className=' flex flex-col max-w-[50%]  shrink-0 rounded-[22.556px] bg-[#FFFFFF] shadow-[0px_0px_4.511px_0px_rgba(0,0,0,0.25)] p-[30px_28px_24px_21px] absolute bottom-[8%] left-[30%]'>
                      <div className="flex items-center">
                        <img src="/assets/icons/Vehiclesrunning.svg" alt="" />
                        <h5 className='text-lg font-normal leading-[-0.36px text-[#F2383A] pl-1'>Vehicle is running on low tire pressure</h5>
                      </div>
                      <div className='flex items-center justify-center py-2'>
                        <img src="/assets/icons/gas-station1.svg" alt="" />
                        <p className='text-[#27403F] text-lg font-normal leading-[31px]'>Go to the closest gas station</p>
                      </div>
                      <button className='text-lg font-normal leading-[31px] rounded-lg bg-[#5DA3E9] w-full text-white p-[10px]'>Add a stop</button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal