import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useEffect, useRef } from "react";

const BatterynetMap = ({ batteryData }) => {
  const ref = useRef(null);
  let map;
  let directionsService;
  let directionsRenderer;
  let markerA;
  let routeDirection;

  const animateMarker = (points, duration) => {
    const numPoints = points.length;
    let startTime = new Date().getTime();

    const moveMarker = () => {
      const elapsedTime = new Date().getTime() - startTime;
      const fraction = elapsedTime / duration;

      if (fraction >= 1) {
        markerA.setPosition(batteryData?.startLatLng);
        routeDirection.setPath(points);
        return;
      }

      const currentIndex = Math.floor(fraction * (numPoints - 1));
      const nextIndex = currentIndex + 1;
      const currentPoint = points[currentIndex];
      const nextPoint = points[nextIndex];

      const newPosition = window.google.maps.geometry.spherical.interpolate(
        currentPoint,
        nextPoint,
        fraction * (numPoints - 1) - currentIndex
      );

      markerA.setPosition(newPosition);

      const remainingRoute = points.slice(nextIndex);
      if (routeDirection) {
        routeDirection.setPath(remainingRoute);
      } else {
        routeDirection = new window.google.maps.Polyline({
          path: remainingRoute,
          strokeColor: "#2A2E35",
          strokeWeight: 5,
          // strokeOpacity: 0.7,
          map: map,
        });
      }

      setTimeout(moveMarker, 15);
    };

    moveMarker();
  };

  const calculateAndDisplayRouteForRealTime = () => {
    if (
      batteryData?.startLatLng?.lat &&
      batteryData?.startLatLng?.lng &&
      batteryData?.endLatLng?.lat &&
      batteryData?.endLatLng?.lng
    ) {
      const startLatLng = new window.google.maps.LatLng(
        batteryData?.startLatLng?.lat,
        batteryData?.startLatLng?.lng
      );
      const endLatLng = new window.google.maps.LatLng(
        batteryData?.endLatLng?.lat,
        batteryData?.endLatLng?.lng
      );

      let markerIcon = "/assets/icons/markerGreen.png";
      const markerSize = new window.google.maps.Size(20, 20);

      if (!markerA) {
        markerA = new window.google.maps.Marker({
          map,
          position: startLatLng,
          title: "Starting Point",
          icon: {
            url: markerIcon,
            scaledSize: markerSize,
          },
        });
      }

      const markerB = new window.google.maps.Marker({
        map,
        position: endLatLng,
        // title: "Fuel Efficiency: " + fuelEfficiency + " %",
        icon: {
          url: markerIcon,
          scaledSize: markerSize,
        },
      });

      directionsService
        .route({
          origin: startLatLng,
          destination: endLatLng,
          travelMode: "DRIVING",
        })
        .then((response) => {
          const route = response.routes[0].overview_path;
          // directionsRenderer.setDirections(response);
          // directionsRenderer.setOptions({
          //   suppressMarkers: true,
          //   polylineOptions: {
          //     strokeColor: "#2A2E35",
          //     strokeWeight: 5,
          //   },
          // });
          animateMarker(route, 180000);
        })
        .catch((e) => {
          console.log("Directions request failed due to " + e);
          directionsRenderer.setMap(null);
        });
    } else {
      directionsRenderer.setMap(null);
      if (map) {
        map.setCenter(new window.google.maps.LatLng(40.273502, -86.126976));
      }
    }
  };

  useEffect(() => {
    const initializeMap = () => {
      if (!map) {
        const centerLatLng = batteryData
          ? new window.google.maps.LatLng(
              (batteryData.startLatLng.lat + batteryData.endLatLng.lat) / 2,
              (batteryData.startLatLng.lng + batteryData.endLatLng.lng) / 2
            )
          : new window.google.maps.LatLng(40.273502, -86.126976);
        map = new window.google.maps.Map(ref.current, {
          center: centerLatLng,
          zoom: 8,
          streetViewControl: false,
          zoomControl: false,
          // mapTypeId: window.google.maps.MapTypeId.HYBRID,
        });
        directionsService = new window.google.maps.DirectionsService();
        directionsRenderer = new window.google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
      }

      if (batteryData && directionsService && directionsRenderer) {
        calculateAndDisplayRouteForRealTime();
      } else {
        directionsRenderer.setMap(null);
      }
    };
    initializeMap();
  }, [batteryData])

  return (
    <div
      ref={ref}
      id="map"
      style={{ borderRadius: "8px", width: "100%", height: "100%" }}
    />
  )
  // const mapOptions = {
  //   center: { lat: 39.7683331, lng: -86.1583502 },
  //   zoom: 9,
  // };
  // return (
  //   <>
  //     <GoogleMap
  //       mapContainerStyle={{
  //         height: "100%",
  //         position: "relative",
  //         width: "100%",
  //         boxShadow:
  //           "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
  //         backgroundColor: "#fff",
  //         borderRadius: "0 0px 8px 8px",
  //       }}
  //       options={mapOptions}
  //     >
  //       <Marker
  //         position={{
  //           lat: batteryData?.startLatLng?.lat,
  //           lng: batteryData?.startLatLng?.lng,
  //         }}
  //         icon={{
  //           url: "/assets/icons/markerGreen.png",
  //           scaledSize: { width: 20, height: 20 },
  //         }}
  //       />
  //     </GoogleMap>
  //   </>
  // );
};

export default BatterynetMap;
