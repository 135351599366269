import { twMerge } from "tailwind-merge";

const Button = ({ className = "", onCLickFunction, children = "Button" }) => {
  const classes = twMerge(
    `bg-blue2 rounded-md text-white font-bold text-lg ${className}`
  );
  return (
    <button type="button" onClick={onCLickFunction} className={classes}>
      {children}
    </button>
  );
};

export default Button;
