import Card from "./Card";
import Button from "./Button";
import { Modal } from "flowbite-react";
import ReactPlayer from "react-player";
import { VideoCompressionContext } from "../../pages/VideoCompression";
import axios from "axios";
import { useContext, useState } from "react";
import { apiURL, mediaURL } from "./util/baseURL";

const UploadFileCard = () => {
  console.log(apiURL);
  const {
    fileUploaded,
    setFileUploaded,
    setFileCompressed,
    setFileCompressing,
    setCompressPayload,
    isPlayingAlong,
  } = useContext(VideoCompressionContext);

  const [reductionPercentage, setReductionPercentage] = useState(65);
  const [videoSize, setVideoSize] = useState("--");
  const [videoDuration, setVideoDuration] = useState("--");
  const [videoFormat, setVideoFormat] = useState("--");
  const [videoResolution, setVideoResolution] = useState("--");
  const [pixelResolution, setPixelResolution] = useState("--");

  // const [selectedFile, setSelectedFile] = useState(null);]
  const [isUploading, setIsUploading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Obtener el archivo seleccionado
    // setSelectedFile(file); // Guardar el archivo en el estado

    if (file) {
      await handleUpload(file); // Realizar la carga automáticamente
    }
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("video", file); // Agregar el archivo al FormData

    try {
      setIsUploading(true);
      // Enviar el archivo al backend
      const response = await axios.post(`${apiURL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setVideoSize(response.data.payload.format.size);
      setVideoDuration(response.data.payload.format.duration);
      setVideoFormat(response.data.payload.streams[0].codec_name);
      setVideoResolution(response.data.payload.video_quality);
      setPixelResolution(
        `${response.data.payload.streams[0].height}x${response.data.payload.streams[0].width}`
      );

      setFileUploaded(true);
      setIsUploading(false);
      // alert("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading the file", error);
    }
  };

  const handleCompress = async () => {
    try {
      setFileCompressing(true);
      const response = await axios.post(`${apiURL}/compress`, {
        reduction: reductionPercentage,
      });

      console.log(response.data.payload);
      setCompressPayload(response.data.payload);
      // alert("Video compressed successfully");
      setFileCompressing(false);
      setFileCompressed(true);
    } catch (error) {
      console.error("Error compressing the file", error);
    }
  };
  return (
    <Card className="flex-1 flex flex-col gap-5 w-full h-full">
      {fileUploaded ? (
        <section className="bg-[#F2F2F2] h-64 md:h-80  justify-center items-center flex flex-col gap-5  shadow-inner grow ">
          <ReactPlayer
            url={`${mediaURL}/video_uploaded.mp4`}
            controls
            width="100%"
            height="100%"
            playing={isPlayingAlong}
          />
        </section>
      ) : (
        <section className="bg-[#F2F2F2] h-64 md:h-80 justify-center items-center flex flex-col gap-5  shadow-inner grow p-5">
          {isUploading ? (
            // <div className="text-lg font-bold">Cargando...</div> // Indicador de carga
            // svg loading
            <>
              <img
                src="/assets/animations/loading1.svg"
                alt="Loading..."
                width={60}
              />
              <p className="text-black font-semibold text-xl">Uploading...</p>
            </>
          ) : (
            <>
              <div className="bg-[#E3E6EC] h-36 w-36 rounded-2xl flex items-center justify-center shadow-2xl">
                <img
                  src="/assets/icons/video-icon.svg"
                  alt="video icon"
                  className="w-25"
                />
              </div>

              <label
                htmlFor="upload"
                className="px-6  bg-blue2 rounded-md text-white font-bold text-lg flex flex-col items-center justify-center cursor-pointer"
              >
                <p>Browse Video</p>
                <p className="text-sm text-gray-300">4K</p>
              </label>
              <input
                id="upload"
                className="hidden"
                name="video"
                type="file"
                accept="video/*"
                onChange={handleFileChange}
              />
            </>
          )}
        </section>
      )}
      <Card className="flex flex-col md:h-2/5 text-green2 justify-start  gap-5">
        <h1 className="text-xl">Original Details</h1>
        <div className="flex justify-between">
          <div className="flex flex-col gap-3 text-base ">
            <p>File Size: </p>
            <p>Duration: </p>
            <p>Resolution: </p>
          </div>

          <div className="flex flex-col gap-3 font-semibold text-base text-end">
            <p>
              {Number(videoSize)
                ? Math.floor((100 * Number(videoSize)) / (1024 * 1024)) / 100
                : "--"}{" "}
              MB
            </p>
            <p>
              {Number(videoSize) ? Math.floor(Number(videoDuration)) : "--"} Sec
            </p>
            <p>
              {videoResolution}{" "}
              <span className="text-xs text-gray1">[{pixelResolution}p]</span>
            </p>
          </div>
        </div>
        <div className="flex justify-center gap-2">
          <Button onCLickFunction={handleCompress} className="px-8 h-10 ">
            Optimize Video
          </Button>
          <Button
            className="w-10 flex justify-center items-center"
            onCLickFunction={() => setOpenModal(true)}
          >
            <svg
              className="w-2.5 h-2.5 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </Button>
          <Modal size="md" show={openModal} onClose={() => setOpenModal(false)}>
            <div className="border border-green1 rounded-lg">
              <Modal.Header>
                <h1 className="text-xl text-green2">Video Reduction Options</h1>
              </Modal.Header>
              <Modal.Body>
                <div className=" flex flex-col gap-5">
                  <div
                    onClick={() => {
                      setReductionPercentage(65);
                      setOpenModal(false);
                    }}
                  >
                    <Card className="border flex flex-col gap-2 cursor-pointer hover:border-green1">
                      <h2 className="text-green2 text-base">Module 1</h2>
                      <div className="flex justify-between">
                        <p className="text-green2 text-sm">
                          Estimated Optimization:
                        </p>
                        <p className="text-green2 text-sm font-semibold">
                          Up to 65%
                        </p>
                      </div>
                      <p className="text-gray1 text-sm">
                        Resolution may change
                      </p>
                    </Card>
                  </div>
                  <div
                    onClick={() => {
                      setReductionPercentage(40);
                      setOpenModal(false);
                    }}
                  >
                    <Card className="border flex flex-col gap-2 cursor-pointer hover:border-green1">
                      <h2 className="text-green2 text-base">Module 2</h2>
                      <div className="flex justify-between">
                        <p className="text-green2 text-sm">
                          Estimated Optimization:
                        </p>
                        <p className="text-green2 text-sm font-semibold">
                          Up to 40%
                        </p>
                      </div>
                      <p className="text-gray1 text-sm">Same Resolution</p>
                    </Card>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="flex justify-center gap-5">
                <Button
                  onCLickFunction={() => setOpenModal(false)}
                  className="px-5 text-sm h-7"
                >
                  Apply Changes
                </Button>
                <Button
                  onCLickFunction={() => setOpenModal(false)}
                  className="text-black text-sm bg-white"
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        </div>
      </Card>
    </Card>
  );
};

export default UploadFileCard;
