import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../redux/action/action";
import Cookies from "js-cookie";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!data.email || !data.password) {
      setErrorMessage("Please fill in all fields.");
      return;
    }
    if (!validateEmail(data.email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    if (data.password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long.");
      return;
    }

    await dispatch(loginUser(data)).then((res) => {
      if (res?.payload?.status === 200) {
        const userDetail = res?.payload?.data;
        Cookies.set("user", JSON.stringify(userDetail), { expires: 1 / 24 });
        navigate("/fleet_view");
      } else {
        setErrorMessage(res?.payload?.data?.message || "An error occurred");
      }
      setData({
        email: "",
        password: "",
      });
    });
  };

  return (
    <div className="h-screen  from-bg  grid place-content-center">
      <div className="w-[800px] h-[800px] rounded-full bg-[#F7F9F9] flex   flex-col p-5 text-center">
        <div className="mx-auto mt-[50px]">
          <img alt="" src="assets/icons/logo.svg" />
        </div>
        <h2 className="text-[#00AEB6] text-[36px] font-bold mt-[40px]">
          Login
        </h2>
        <h3 className="text-[#93B9C4] text-[16px]">Sign in to your account</h3>
        {errorMessage && (
          <p className="text-[red] text-lg mt-[10px]">{errorMessage}</p>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mx-auto mt-[45px]">
            <input
              type="email"
              className="w-[465px] h-[60px] rounded-md pl-[27px]  focus:outline-none hover:outline-none font-[600] placeholder:text-[#6FA2B0] text-[#6FA2B0]"
              placeholder="Email"
              name="email"
              value={data.email}
              onChange={handleChange}
              // required
            />
            <br />

            <div className="relative">
              <input
                id="pass"
                type="password"
                className=" relative  w-[465px] h-[60px] rounded-md mt-[24px] pl-[27px] pr-[100px]  focus:outline-none hover:outline-none font-[600] placeholder:text-[#6FA2B0] text-[#6FA2B0]"
                placeholder="Password"
                name="password"
                value={data.password}
                onChange={handleChange}
                // required
              />
              {/*  */}
              <div className="hidden">
                <div className="border-b-red-400 border-2 absolute right-[39px] !top-[4.2rem] w-[87%]"></div>
                <div className="absolute top-[41px] right-[39px] flex justify-end">
                  <img alt="" src="/assets/icons/closeicon.svg" />
                </div>
              </div>
              {/*  */}
            </div>
          </div>

          <div className="flex mx-auto mt-[26px] gap-[38px] justify-center">
            <h4 className="text-[#93B9C4] text-[16px]">Remember me</h4>
            <h4 className="text-[#93B9C4] text-[16px] cursor-pointer">
              <Link to="/forget-password">Forgot Password?</Link>
            </h4>
          </div>

          <button
            className="mt-[43px] bg-[#00AEB6] rounded-lg mx-auto w-[187px] h-[50px] text-white text-lg font-bold"
            type="submit"
          >
            Login
          </button>
        </form>

        <h2 className="mt-[23px] text-[16px] text-[#00AEB6] font-[500]">
          Don´t have an account?{" "}
          <span className="font-bold ml-2 cursor-pointer">
            <Link to="/signup">Register</Link>
          </span>
        </h2>
      </div>
    </div>
  );
};

export default Login;
