import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import axios from "axios";
import baseUrl from "../../../util/baseUrl";
import { useTranslation } from "react-i18next";
import { mdiStopCircleOutline, mdiVolumeHigh } from "@mdi/js";
import Icon from "@mdi/react";

const Popup = ({ openTire, setOpenTire, vinRealTimeData, language }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioObject, setAudioObject] = useState();
  const { t } = useTranslation();
  const [snapshotData, setSnapshotData] = useState(null);
  const [currentNotificaion, setCurrentNotificaion] = useState(null);

  const getText = (notificationNo, data) => {
    switch (notificationNo) {
      case "No-1":
        return `Maintain the vehicle average speed of ${data?.speed} mph, and save up to $ ${data?.fleet_Speeding_saving_data} in next 1000 miles.`;
      case "No-2":
        return `Don´t leave the vehicle idle for more than ${data?.idleTime.toFixed(2)} mins, it may cost $ ${data?.fleet_Idling_Saving_data}`;
      case "No-3":
        return `Increase the tire pressure by FL-${data?.fl}, FR-${data?.fr}, RR-${data?.rr}, RL-${data?.rl}, you may save up to $ ${data?.fleet_Tire_Pressure_Saving_data} in next 1000 miles.`;
      default:
        return "";
    }
  };

  const speak = async (text, notificationNo) => {
    if (isSpeaking) {
      stopAudio();
    }
    setIsSpeaking(true);
    setCurrentNotificaion(notificationNo);
    setSnapshotData(vinRealTimeData);
    try {
      const response = await axios.get(
        `${baseUrl}/text_to_speech?text=${encodeURIComponent(
          text
        )}&language=${language}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "audio/mp3" });
      const blobUrl = URL.createObjectURL(blob);
      const audio = new Audio(blobUrl);
      audio.onended = () => {
        setIsSpeaking(false);
        setCurrentNotificaion(null);
      };
      audio.play();
      setAudioObject(audio);
    } catch (err) {
      console.log("Error converting text to speech", err);
    }
  };
  const stopAudio = () => {
    if (audioObject) {
      audioObject.pause();
      audioObject.currentTime = 0;
      setIsSpeaking(false);
      setAudioObject(null);
      setCurrentNotificaion(null);
    }
  };

  useEffect(() => {
    if (!openTire) {
      stopAudio();
    }
  }, [openTire]);

  const displayedData = isSpeaking ? snapshotData : vinRealTimeData;

  return (
    <>
      <Transition.Root show={openTire} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={setOpenTire}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-[8px] bg-white dark:bg-[#222222] text-left shadow-xl transition-all sm:my-8 w-full sm:w-[60%] sm:p-[24px_24px_30px_24px] ">
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-[13px]">
                        <img
                          src="/assets/icons/notification-bing-blue.svg"
                          alt="notification"
                          className="text-[#000] w-8"
                        />
                        <div>
                          <h1 className="font-bold text-[20px] dark:text-white">
                            {t("Improve Now!")}
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-center gap-[15px]">
                        <IoCloseCircleOutline
                          onClick={() => setOpenTire(false)}
                          className="text-[#5DA3E9] cursor-pointer text-[30px]"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex mt-[30px] justify-between items-center">
                        <div className="flex items-center gap-[30px] w-full">
                          <div className=" mb-[10px]">
                            <img
                              src="/assets/icons/Group 239742-2.svg"
                              alt=""
                              className="w-[51px] min-w-[51px] h-[51px]"
                            />
                          </div>
                          <div className="flex justify-between items-center w-full border-b border-b-[#f2efef] pb-[10px]">
                            <h1 className="font-normal text-[17px] text-[#000] dark:text-white">
                              {t("Maintain the vehicle average speed of")}{" "}
                              {displayedData?.speed} {t("mph, and save up to")} ${" "}
                              {displayedData?.fleet_Speeding_saving_data}{" "}
                              {t("in next 1000 miles.")}
                            </h1>
                            <div className="flex flex-none gap-2 items-center">
                              {isSpeaking && currentNotificaion === "No-1" ? (
                                <Icon
                                  path={mdiStopCircleOutline}
                                  size={"25px"}
                                  className="dark:text-white cursor-pointer"
                                  onClick={stopAudio}
                                />
                              ) : (
                                <Icon
                                  path={mdiVolumeHigh}
                                  size={"25px"}
                                  className="dark:text-white cursor-pointer"
                                  onClick={() =>
                                    speak(
                                      getText("No-1", displayedData),
                                      "No-1"
                                    )
                                  }
                                />
                              )}
                              <button className=" px-[10px] py-[5px] bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative">
                                {t("Take Action")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-[30px] justify-between items-center">
                        <div className="flex items-center gap-[30px] w-full">
                          <div className=" mb-[10px]">
                            <img
                              src="/assets/icons/Group 239658-2.svg"
                              alt=""
                              className="w-[51px] min-w-[51px] h-[51px]"
                            />
                          </div>
                          <div className="flex justify-between items-center w-full border-b border-b-[#f2efef] pb-[10px]">
                            <div>
                              <h1 className="font-normal text-[17px] text-[#000] dark:text-white">
                                {t(
                                  "Don´t leave the vehicle idle for more than"
                                )}{" "}
                                {displayedData?.idleTime.toFixed(2)}{" "}
                                {t("mins, it may cost")} ${" "}
                                {displayedData?.fleet_Idling_Saving_data}
                              </h1>
                            </div>
                            <div className="flex flex-none gap-2 items-center">
                              {isSpeaking && currentNotificaion === "No-2" ? (
                                <Icon
                                  path={mdiStopCircleOutline}
                                  size={"25px"}
                                  className="dark:text-white cursor-pointer"
                                  onClick={stopAudio}
                                />
                              ) : (
                                <Icon
                                  path={mdiVolumeHigh}
                                  size={"25px"}
                                  className="dark:text-white cursor-pointer"
                                  onClick={() =>
                                    speak(
                                      getText("No-2", displayedData),
                                      "No-2"
                                    )
                                  }
                                />
                              )}
                              <button className=" px-[10px] py-[5px] bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative">
                                {t("Take Action")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-[30px] justify-between items-center">
                        <div className="flex items-center gap-[30px] w-full">
                          <div className=" mb-[10px]">
                            <img
                              src="/assets/icons/Group 239679-2.svg"
                              alt=""
                              className="w-[51px] min-w-[51px] h-[51px]"
                            />
                          </div>
                          <div className="flex justify-between items-center w-full border-b border-b-[#f2efef] pb-[10px]">
                            <div>
                              <h1 className="font-normal text-[17px] text-[#000] dark:text-white">
                                {t("Increase the tire pressure by")} {t("FL")}-
                                {displayedData?.fl}, {t("FR")}-
                                {displayedData?.fr}, {t("RR")}-
                                {displayedData?.rr}, {t("RL")}-
                                {displayedData?.rl}, {t("you may save up to")} ${" "}
                                {displayedData?.fleet_Tire_Pressure_Saving_data}{" "}
                                {t("in next 1000 miles.")}
                              </h1>
                            </div>
                            <div className="flex flex-none gap-2 items-center">
                              {isSpeaking && currentNotificaion === "No-3" ? (
                                <Icon
                                  path={mdiStopCircleOutline}
                                  size={"25px"}
                                  className="dark:text-white cursor-pointer"
                                  onClick={stopAudio}
                                />
                              ) : (
                                <Icon
                                  path={mdiVolumeHigh}
                                  size={"25px"}
                                  className="dark:text-white cursor-pointer"
                                  onClick={() =>
                                    speak(
                                      getText("No-3", displayedData),
                                      "No-3"
                                    )
                                  }
                                />
                              )}
                              <button className="px-[10px] py-[5px] bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative">
                                {t("Take Action")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default Popup;
