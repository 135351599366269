import React, { useEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import { useDispatch } from 'react-redux';
import { getMultivariateData } from '../../../redux/action/action';
import { useTranslation } from 'react-i18next';

highcharts3d(Highcharts);

const AreaChart = ({ maltivariateButton, maltivariateTimeStamp, isDarkTheme }) => {


  const dispatch = useDispatch()

  const IndexValue = useRef(0);
  const [chartData, setChartData] = useState()
  const TextColor = isDarkTheme ? "#ffffff" : "#000000";
  const backgroundColor = isDarkTheme ? "#222222" : "#ffffff";
  const { t } = useTranslation()

  // useEffect(() => {
  //   const getchartData = async () => {
  //     const requestParams = { index: IndexValue, field_name: maltivariateButton, canvas: maltivariateTimeStamp };
  //     try {
  //       const response = await dispatch(getMultivariateData(requestParams));
  //       if (response && response?.payload) {
  //         const responseData = response?.payload;
  //         setChartData(responseData);
  //         setIndexValue(responseData?.last_index)
  //       }
  //     } catch (error) {
  //       console.log("🚀 ~ error:", error);
  //     }
  //   };
  //   getchartData();
  // }, [dispatch, maltivariateButton, maltivariateTimeStamp]);

  useEffect(() => {
    const fetchData = async () => {
      const requestParams = { index: IndexValue.current, field_name: maltivariateButton, canvas: maltivariateTimeStamp };
      try {
        const response = await dispatch(getMultivariateData(requestParams));
        if (response && response?.payload) {
          const responseData = response?.payload;
          setChartData(responseData);
          IndexValue.current = responseData?.last_index || 0;
        }
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    };
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [dispatch, maltivariateButton, maltivariateTimeStamp]);

  const options = {
    chart: {
      type: 'area',
      height: '600px',
      options3d: {
        enabled: true,
        alpha: 17,
        beta: 320,
        depth: 200,
      },
      backgroundColor: backgroundColor,
    },
    title: {
      style: {
        display: 'none'
      }
    },

    xAxis: {
      categories: chartData?.Date || [],
      labels: {
        rotation: -45,
        step: 1,
        style: {
        fontSize:10,
        color: TextColor,
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          color: TextColor,
        },
      },
    },
    zAxis: {
      title: {
        text: '',
      },
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillOpacity: 0.8,
        depth: 70,
        states: {
          inactive: {
            enabled: false
          }
        }
      },
      series: {
        depth: 45,
        animation: true,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: TextColor,
      },
    },
    series: [
      {
        name: t('Pickup Truck'),
        color: 'rgba(122, 154, 188, 0.6)',
        data: Array.from(chartData?.pickup_track || []),
      },
      {
        name: t('Sedan'),
        color: 'rgba(183, 160, 88, 0.6)',
        data: Array.from(chartData?.sedan || []),
      },
      {
        name: t('Vans'),
        color: 'rgba(107, 192, 179, 0.6)',
        data: Array.from(chartData?.van || []),
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default AreaChart