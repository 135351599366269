
import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Popover, PopoverContent, PopoverHandler } from '@material-tailwind/react'
import Icon from '@mdi/react'
import { mdiArrowDownThin, mdiArrowUpThin, mdiClose, mdiStar } from '@mdi/js'
import ActiveVehicleChart from '../Chart/ActiveVehicleChart'
import { UseContextData } from '../../../context/dateContext'
import { useTranslation } from 'react-i18next'


const ConsuptionModel = (props) => {
  const cancelButtonRef = useRef(null)
  const { isDarkTheme } = UseContextData()
  const { t } = useTranslation();

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-[30]" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform pt-10 overflow-hidden px-20 pb-10 rounded-lg bg-white dark:bg-[#252525] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[1000px]">
                <div className='flex justify-between items-center'>
                  <h1 className='text-[28px] mb-6 text-gray-800 dark:text-white'>
                    {t('Consumption Profile')}
                  </h1>
                  <button className='border border-[#5DA3E9] dark:text-white p-1 rounded-full' onClick={() => props.setOpen(false)}><Icon path={mdiClose} size={1} className="cursor-pointer text-[#5DA3E9] dark:text-white" /></button>
                </div>
                <div className='flex items-center  gap-4'>
                  <div className='bg-white dark:bg-[#1d1d1d] shadow-xl rounded-lg w-1/3 px-4 pt-14 pb-4'>
                    <div className='flex flex-col justify-between items-start'>
                      <div className='flex mb-6'>
                        <img src="/assets/icons/fuel-consumption2.svg" alt="fuel-consumption" className='mr-2' />
                        <div className='text-dark1 dark:text-white'>
                          <h3 className='font-bold'>{t('Fuel')}</h3>
                          <h4>{t('consumption')}</h4>
                        </div>
                      </div>
                      <div className='mb-10'>
                        <div className='text-gray-800 font-medium text-2xl mb-2.5 flex items-center dark:text-white'>{props.stateData.FuelConsumed} <sub className='text-secondary ml-10 text-xs dark:text-white'> {t('Gallons')}</sub></div>
                        <div className='flex items-center'>
                          <div className='flex items-center'>
                            {/* <span className='text-sm font-bold'>{props.stateData.fuelPerMile?.toFixed(2)} gal/mile</span> */}
                            <Icon path={props.stateData.change_FuelConsumed <= 0 ? mdiArrowDownThin : mdiArrowUpThin} size={1} className={`${props.stateData.change_FuelConsumed < 0 ? "text-[#0DBF4F]" : 'text-[#F2383A]'}`} />
                            <span className={`${props.stateData.change_FuelConsumed < 0 ? "text-[#0DBF4F]" : 'text-[#F2383A]'} text-sm font-bold`}>{props.stateData.change_FuelConsumed.toFixed(2)} %</span>
                          </div>
                          <div className='text-secondary ml-2 text-xs dark:text-white'>{t('vs')} {t('yesterday')}</div>
                        </div>
                      </div>
                      <Popover placement='top-start'>
                        <PopoverHandler>
                          <div>
                            <img src="/assets/icons/graph.svg" alt="graph" className='cursor-pointer' onClick={() => props.setgraphSelection("Fuel Consumed")} />
                          </div>
                        </PopoverHandler>
                        <PopoverContent className='z-30 w-[350px] dark:bg-[#444444] dark:border-[#68DCB5]'>
                          <ActiveVehicleChart graphSelection={props.graphSelection} isDarkTheme={isDarkTheme} />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                  <div className='bg-white dark:bg-[#1d1d1d] shadow-xl rounded-lg w-1/3 px-4 pt-14 pb-4'>
                    <div className='flex flex-col justify-between items-start'>
                      <div className='flex mb-6'>
                        <img src="/assets/icons/enery2.svg" alt="energy-used" className='mr-2' />
                        <div className='text-dark1 dark:text-white'>
                          <h3 className='font-bold'>{t('Energy')}</h3>
                          <h4>{t('used')}</h4>
                        </div>
                      </div>
                      <div className='mb-10'>
                        <div className='text-gray-800 font-medium text-2xl mb-2.5 flex items-center dark:text-white'>
                          {props.stateData.Energy_KJ.toFixed(2)} 
                          <sub className='text-secondary ml-10 text-xs relative dark:text-white'> 
                          {t('Mega Joule')}

                          </sub>
                        </div>
                        <div className='flex items-center'>
                          <div className='flex items-center'>
                          
                            {/* <span className='text-sm font-bold'>{props.stateData.energyPerMile?.toFixed(2)} KJ/mile</span> */}
                            <Icon path={props.stateData.change_EnergyKJ <= 0 ? mdiArrowDownThin : mdiArrowUpThin} size={1} className={`${props.stateData.change_EnergyKJ < 0 ? "text-[#0DBF4F]" : 'text-[#F2383A]'}`} />
                            <span className={`${props.stateData.change_EnergyKJ < 0 ? "text-[#0DBF4F]" : 'text-[#F2383A]'} text-sm font-bold`}>{props.stateData.change_EnergyKJ.toFixed(2)} %</span>
                          </div>
                          <div className='text-secondary ml-2 text-xs dark:text-white'>{t('vs')} {t('yesterday')}</div>
                        </div>
                      </div>
                      <Popover placement='top'>
                        <PopoverHandler>
                          <div>
                            <img src="/assets/icons/graph.svg" alt="graph" className='cursor-pointer' onClick={() => props.setgraphSelection("Energy(KJ)")} />
                          </div>
                        </PopoverHandler>
                        <PopoverContent className='z-30 w-[350px] dark:bg-[#444444] dark:border-[#68DCB5]'>
                          <ActiveVehicleChart graphSelection={props.graphSelection} isDarkTheme={isDarkTheme} />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                  <div className='bg-white dark:bg-[#1d1d1d] shadow-xl rounded-lg w-1/3 px-4 pt-14 pb-4'>
                    <div className='flex flex-col justify-between items-start'>
                      <div className='flex mb-6'>
                        <img src="/assets/icons/co2-2.svg" alt="emission-generated" className='mr-2' />
                        <div className='text-dark1 dark:text-white'>
                          <h3 className='font-bold'>{t('Emissions')}</h3>
                          <h4>{t('generated')}</h4>
                        </div>
                      </div>
                      <div className='mb-10'>
                        <div className='text-gray-800 font-medium text-2xl mb-2.5 flex items-center dark:text-white'>
                          {props.stateData.CO2_Emission}
                          <sub className='text-secondary ml-10 text-xs dark:text-white'> {t('kg')}</sub>
                        </div>
                        <div className='flex items-center'>
                          <div className='flex items-center'>
                            {/* <span className='text-sm font-bold'>{props.stateData.emissionPerMile} Kg/mile</span> */}
                            <Icon path={props.stateData.change_CO2EmissionKg <= 0 ? mdiArrowDownThin : mdiArrowUpThin} size={1} className={`${props.stateData.change_CO2EmissionKg < 0 ? "text-[#0DBF4F]" : 'text-[#F2383A]'}`} />
                            <span className={`${props.stateData.change_CO2EmissionKg < 0 ? "text-[#0DBF4F]" : 'text-[#F2383A]'} text-sm font-bold`}>{props.stateData.change_CO2EmissionKg.toFixed(2)} %</span>
                          </div>
                          <div className='text-secondary ml-2 text-xs dark:text-white'>vs {t('yesterday')}</div>
                        </div>
                      </div>
                      <Popover placement='top'>
                        <PopoverHandler>
                          <div>
                            <img src="/assets/icons/graph.svg" alt="graph" className='cursor-pointer' onClick={() => props.setgraphSelection("CO2 Emission (Kg)")} />
                          </div>
                        </PopoverHandler>
                        <PopoverContent className='z-30 w-[350px] dark:bg-[#444444] dark:border-[#68DCB5]'>
                          <ActiveVehicleChart graphSelection={props.graphSelection} isDarkTheme={isDarkTheme} />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ConsuptionModel