import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Icon from "@mdi/react";
import { mdiClose, mdiStopCircleOutline, mdiVolumeHigh } from "@mdi/js";
import { UseManageData } from "../../../context/manageData";
import axios from "axios";
import baseUrl from "../../../util/baseUrl";
import { useTranslation } from "react-i18next";

const EfficiencyModal = ({ open, setOpen, language }) => {
  const { stateData } = UseManageData();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioObject, setAudioObject] = useState();
  const [snapshotData, setSnapshotData] = useState(null);
  const { t, i18n } = useTranslation();
  const [currentVehicle, setCurrentVehicle] = useState(null);

  const getText = (vehicleType, data) => {
    switch (vehicleType) {
      case "Vans":
        return `Vans, Keep your tire pressure between ${data.suv_FrontTire_pressure_recom}psi to ${data.suv_RearTire_pressure_recom}psi to save up to $ ${data.VAN_Tire_Pressure_Saving} in next 1000 miles per vehicle. Keep your speed between ${data.suv_lower_speed_limit} to ${data.suv_upper_speed_limit}mph to save up to $ ${data.VAN_Speeding_saving} per vehicle. Leaving vehicle Idle for more than ${data.suv_idletime_recom}mins, It may cost about $ ${data.VAN_Idling_Saving} per vehicle. Advice Drivers`;
      case "Sedans":
        return `Sedans, Keep your tire pressure between ${data.sedan_FrontTire_pressure_recom}psi to ${data.sedan_RearTire_pressure_recom}psi to save up to $ ${data.Sedan_Tire_Pressure_Saving} in next 1000 miles per vehicle. Keep your speed between ${data.sedan_lower_speed_limit} to ${data.sedan_upper_speed_limit}mph to save up to $ ${data.Sedan_Speeding_saving} per vehicle. Leaving vehicle Idle for more than ${data.sedan_idletime_recom}mins, It may cost about $ ${data.Sedan_Idling_Saving} per vehicle. Advice Drivers`;
      case "PickUp Trucks":
        return `PickUp Trucks, Keep your tire pressure between ${data.pickup_FrontTire_pressure_recom}psi to ${data.pickup_RealTire_pressure_recom}psi to save up to $ ${data.SUV_Tire_Pressure_Saving} in next 1000 miles per vehicle. Keep your speed between ${data.pickup_lower_speed_limit} to ${data.pickup_upper_speed_limit}mph to save up to $ ${data.SUV_Speeding_saving} per vehicle. Leaving vehicle Idle for more than ${data.pickup_idletime_recom}mins, It may cost about $ ${data.SUV_Idling_Saving} per vehicle. Advice Drivers`;
      default:
        return "";
    }
  };

  const speak = async (text, vehicleType) => {
    if (isSpeaking) {
      stopAudio();
    }
    setIsSpeaking(true);
    setCurrentVehicle(vehicleType);
    setSnapshotData(stateData);
    try {
      const response = await axios.get(
        `${baseUrl}/text_to_speech?text=${encodeURIComponent(
          text
        )}&language=${language}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "audio/mp3" });
      const blobUrl = URL.createObjectURL(blob);
      const audio = new Audio(blobUrl);
      audio.onended = () => {
        setIsSpeaking(false);
        setCurrentVehicle(null);
      };
      audio.play();
      setAudioObject(audio);
    } catch (err) {
      console.log("Error converting text to speech", err);
    }
  };
  const stopAudio = () => {
    if (audioObject) {
      audioObject.pause();
      audioObject.currentTime = 0;
      setIsSpeaking(false);
      setAudioObject(null);
      setCurrentVehicle(null);
    }
  };

  useEffect(() => {
    if (!open) {
      stopAudio();
    }
  }, [open]);

  const displayedData = isSpeaking ? snapshotData : stateData;

  if (!displayedData) {
    return null;
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-[8px] bg-white dark:bg-[#252525] text-left shadow-xl transition-all sm:my-8 max-w-5xl w-full sm:p-[24px_24px_30px_24px] ">
                  <div>
                    <div className="flex justify-between items-center mb-10">
                      <h1 className="font-semibold text-xl dark:text-white">
                        {t("Improve Efficiency")}
                      </h1>
                      <div>
                        <Icon
                          path={mdiClose}
                          size={1}
                          onClick={setOpen}
                          className="cursor-pointer dark:text-white"
                        />
                      </div>
                    </div>
                    <div className="grid gap-4 grid-cols-3">
                      <div className="shadow-xl p-6 rounded-lg dark:bg-[#1d1d1d]">
                        <div className="flex items-center justify-between">
                          <div className="rounded-full h-10 flex items-center justify-center mr-2 gap-1">
                            <img
                              src="/assets/icons/transport2.svg"
                              alt="transport"
                              className="w-8 h-8"
                            />
                            <div className="font-[500] text-lg dark:text-white">
                              {t("Vans")}
                            </div>
                          </div>
                          <div>
                            {isSpeaking && currentVehicle === "Vans" ? (
                              <Icon
                                path={mdiStopCircleOutline}
                                size={"25px"}
                                className="dark:text-white cursor-pointer"
                                onClick={stopAudio}
                              />
                            ) : (
                              <Icon
                                path={mdiVolumeHigh}
                                size={"25px"}
                                className="dark:text-white cursor-pointer"
                                onClick={() =>
                                  speak(getText("Vans", displayedData), "Vans")
                                }
                              />
                            )}
                          </div>
                        </div>
                        <ol className="mb-4 mt-2 min-h-[164px] max-w-[220px] mx-auto list-disc">
                          <li className="mb-3 text-sm text-gray-900 dark:text-white">
                            {t("Keep your")}{" "}
                            <span className="font-medium">
                              {t("tire pressure")}
                            </span>{" "}
                            {t("between")}{" "}
                            <span className="font-medium">
                              {displayedData.suv_FrontTire_pressure_recom}
                            </span>
                            {t("psi")} {t("to")}{" "}
                            <span className="font-medium">
                              {displayedData.suv_RearTire_pressure_recom}
                            </span>
                            {t("psi to save up to")}{" "}
                            <span className="font-medium">
                              $ {displayedData.VAN_Tire_Pressure_Saving}
                            </span>{" "}
                            {t("in next 1000 miles per vehicle.")}
                          </li>
                          <li className="mb-3 text-sm text-gray-900 dark:text-white">
                            {t("Keep your")}{" "}
                            <span className="font-medium">{t("speed")}</span>{" "}
                            {t("between")}{" "}
                            <span className="font-medium">
                              {displayedData.suv_lower_speed_limit}
                            </span>{" "}
                            {t("to")}{" "}
                            <span className="font-medium">
                              {displayedData.suv_upper_speed_limit}
                            </span>
                            {t("mph to save up to")}{" "}
                            <span className="font-medium">
                              $ {displayedData.VAN_Speeding_saving}
                            </span>{" "}
                            {t("per vehicle.")}
                          </li>
                          {i18n.language === "es" ? (
                            <>
                              <li className="text-sm text-gray-900 dark:text-white">
                                {
                                  "Dejar el vehículo parado / inactivo por más de"
                                }{" "}
                                <span className="font-medium">
                                  {displayedData.suv_idletime_recom}
                                </span>{" "}
                                {"minutos, puede costar alrededor de"}{" "}
                                <span className="font-medium">
                                  $ {displayedData.VAN_Idling_Saving}
                                </span>{" "}
                                {"por vehículo"}
                              </li>
                            </>
                          ) : (
                            <>
                              <li className="text-sm text-gray-900 dark:text-white">
                                {t("Leaving vehicle")}{" "}
                                <span className="font-medium">{t("Idle")}</span>{" "}
                                {t("for more than")}{" "}
                                <span className="font-medium">
                                  {displayedData.suv_idletime_recom}
                                </span>
                                {t("mins, It may cost about")}{" "}
                                <span className="font-medium">
                                  $ {displayedData.VAN_Idling_Saving}
                                </span>{" "}
                                {t("per vehicle.")}
                              </li>
                            </>
                          )}
                        </ol>
                        {/* <div className="text-center mb-2">
                          <Icon path={mdiArrowUpThin} size={1} className='text-[#F2383A] inline' />
                          <span className="text-[#F2383A]">3.1%</span> &nbsp;
                          <span className="text-sm text-gray-700">vs yesterday</span>
                        </div> */}
                        <button className="px-4 py-2 mt-2 bg-[#5DA3E9] text-white text-[16px] mx-auto font-bold rounded-md flex items-center justify-center relative gap-[20px]">
                          {t("Advice Drivers")}
                        </button>
                      </div>
                      <div className="shadow-xl p-6 rounded-lg dark:bg-[#1d1d1d]">
                        <div className="flex items-center justify-between">
                          <div className="rounded-full  h-10 flex items-center justify-center mr-2 gap-1">
                            <img
                              src="/assets/icons/car3.svg"
                              alt="car2"
                              className="w-8 h-8"
                            />
                            <div className="font-[500] text-lg dark:text-white">
                              {t("Sedans")}
                            </div>
                          </div>
                          <div>
                            {isSpeaking && currentVehicle === "Sedans" ? (
                              <Icon
                                path={mdiStopCircleOutline}
                                size={"25px"}
                                className="dark:text-white cursor-pointer"
                                onClick={stopAudio}
                              />
                            ) : (
                              <Icon
                                path={mdiVolumeHigh}
                                size={"25px"}
                                className="dark:text-white cursor-pointer"
                                onClick={() =>
                                  speak(
                                    getText("Sedans", displayedData),
                                    "Sedans"
                                  )
                                }
                              />
                            )}
                          </div>
                        </div>
                        <ol className="mb-4 mt-2 min-h-[164px] max-w-[220px] mx-auto list-disc">
                          <li className="mb-3 text-sm text-gray-900 dark:text-white">
                            {t("Keep your")}{" "}
                            <span className="font-medium">
                              {t("tire pressure")}
                            </span>{" "}
                            {t("between")}{" "}
                            <span className="font-medium">
                              {displayedData.sedan_FrontTire_pressure_recom}
                            </span>
                            {t("psi")} {t("to")}{" "}
                            <span className="font-medium">
                              {displayedData.sedan_RearTire_pressure_recom}
                            </span>
                            {t("psi to save up to")}{" "}
                            <span className="font-medium">
                              $ {displayedData.Sedan_Tire_Pressure_Saving}
                            </span>{" "}
                            {t("in next 1000 miles per vehicle.")}
                          </li>
                          <li className="mb-3 text-sm text-gray-900 dark:text-white">
                            {t("Keep your")}{" "}
                            <span className="font-medium">{t("speed")}</span>{" "}
                            {t("between")}{" "}
                            <span className="font-medium">
                              {displayedData.sedan_lower_speed_limit}
                            </span>{" "}
                            {t("to")}{" "}
                            <span className="font-medium">
                              {displayedData.sedan_upper_speed_limit}
                            </span>
                            {t("mph to save up to")}{" "}
                            <span className="font-medium">
                              $ {displayedData.Sedan_Speeding_saving}
                            </span>{" "}
                            {t("per vehicle.")}
                          </li>
                          {i18n.language === "es" ? (
                            <>
                              <li className="text-sm text-gray-900 dark:text-white">
                                {
                                  "Dejar el vehículo parado / inactivo por más de"
                                }{" "}
                                <span className="font-medium">
                                  {displayedData.sedan_idletime_recom}
                                </span>{" "}
                                {"minutos, puede costar alrededor de"}{" "}
                                <span className="font-medium">
                                  $ {displayedData.Sedan_Idling_Saving}
                                </span>{" "}
                                {"por vehículo"}
                              </li>
                            </>
                          ) : (
                            <>
                              <li className="text-sm text-gray-900 dark:text-white">
                                {t("Leaving vehicle")}{" "}
                                <span className="font-medium">{t("Idle")}</span>{" "}
                                {t("for more than")}{" "}
                                <span className="font-medium">
                                  {displayedData.sedan_idletime_recom}
                                </span>
                                {t("mins, It may cost about")}{" "}
                                <span className="font-medium">
                                  $ {displayedData.Sedan_Idling_Saving}
                                </span>{" "}
                                {t("per vehicle.")}
                              </li>
                            </>
                          )}
                        </ol>
                        {/* <div className="text-center mb-2">
                          <Icon path={mdiArrowUpThin} size={1} className='text-[#F2383A] inline' />
                          <span className="text-[#F2383A]">3.1%</span> &nbsp;
                          <span className="text-sm text-gray-700">vs yesterday</span>
                        </div> */}
                        <button className="px-4 py-2 mt-2 bg-[#5DA3E9] text-white text-[16px] mx-auto font-bold rounded-md flex items-center justify-center relative gap-[20px]">
                          {t("Advice Drivers")}
                        </button>
                      </div>
                      <div className="shadow-xl p-6 rounded-lg dark:bg-[#1d1d1d]">
                        <div className="flex items-center justify-between">
                          <div className="rounded-full  h-10 flex items-center justify-center mr-2 gap-1">
                            <img
                              src="/assets/icons/tempo2.svg"
                              alt="tempo"
                              className="w-8 h-8"
                            />
                            <div className="font-[500] text-lg dark:text-white">
                              {t("PickUp Trucks")}
                            </div>
                          </div>
                          <div>
                            {isSpeaking &&
                            currentVehicle === "PickUp Trucks" ? (
                              <Icon
                                path={mdiStopCircleOutline}
                                size={"25px"}
                                className="dark:text-white cursor-pointer"
                                onClick={stopAudio}
                              />
                            ) : (
                              <Icon
                                path={mdiVolumeHigh}
                                size={"25px"}
                                className="dark:text-white cursor-pointer"
                                onClick={() =>
                                  speak(
                                    getText("PickUp Trucks", displayedData),
                                    "PickUp Trucks"
                                  )
                                }
                              />
                            )}
                          </div>
                        </div>
                        <ol className="mb-4 mt-2 min-h-[164px] max-w-[220px] mx-auto list-disc">
                          <li className="mb-3 text-sm text-gray-900 dark:text-white">
                            {t("Keep your")}{" "}
                            <span className="font-medium">
                              {t("tire pressure")}
                            </span>{" "}
                            {t("between")}{" "}
                            <span className="font-medium">
                              {displayedData.pickup_FrontTire_pressure_recom}
                            </span>
                            {t("psi")} {t("to")}{" "}
                            <span className="font-medium">
                              {displayedData.pickup_RealTire_pressure_recom}
                            </span>
                            {t("psi to save up to")}{" "}
                            <span className="font-medium">
                              $ {displayedData.SUV_Tire_Pressure_Saving}
                            </span>{" "}
                            {t("in next 1000 miles per vehicle.")}
                          </li>
                          <li className="mb-3 text-sm text-gray-900 dark:text-white">
                            {t("Keep your")}{" "}
                            <span className="font-medium">{t("speed")}</span>{" "}
                            {t("between")}{" "}
                            <span className="font-medium">
                              {displayedData.pickup_lower_speed_limit}
                            </span>{" "}
                            {t("to")}{" "}
                            <span className="font-medium">
                              {displayedData.pickup_upper_speed_limit}
                            </span>
                            {t("mph to save up to")}{" "}
                            <span className="font-medium">
                              $ {displayedData.SUV_Speeding_saving}
                            </span>{" "}
                            {t("per vehicle.")}
                          </li>
                          {i18n.language === "es" ? (
                            <>
                              <li className="text-sm text-gray-900 dark:text-white">
                                {
                                  "Dejar el vehículo parado / inactivo por más de"
                                }{" "}
                                <span className="font-medium">
                                  {displayedData.pickup_idletime_recom}
                                </span>{" "}
                                {"minutos, puede costar alrededor de"}{" "}
                                <span className="font-medium">
                                  $ {displayedData.Pickup_Idling_Saving}
                                </span>{" "}
                                {"por vehículo"}
                              </li>
                            </>
                          ) : (
                            <>
                              <li className="text-sm text-gray-900 dark:text-white">
                                {t("Leaving vehicle")}{" "}
                                <span className="font-medium">{t("Idle")}</span>{" "}
                                {t("for more than")}{" "}
                                <span className="font-medium">
                                  {displayedData.pickup_idletime_recom}
                                </span>
                                {t("mins, It may cost about")}{" "}
                                <span className="font-medium">
                                  $ {displayedData.Pickup_Idling_Saving}
                                </span>{" "}
                                {t("per vehicle.")}
                              </li>
                            </>
                          )}
                        </ol>
                        {/* <div className="text-center mb-2">
                          <Icon path={mdiArrowUpThin} size={1} className='text-[#F2383A] inline' />
                          <span className="text-[#F2383A]">3.1%</span> &nbsp;
                          <span className="text-sm text-gray-700">vs yesterday</span>
                        </div> */}
                        <button className="px-4 py-2 mt-2 bg-[#5DA3E9] text-white text-[16px] mx-auto font-bold rounded-md flex items-center justify-center relative gap-[20px]">
                          {t("Advice Drivers")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default EfficiencyModal;
